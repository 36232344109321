<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Contact Us</h3>
                    <ul class="contact-us-link">
                        <li>
                            <i class='bx bx-map'></i>
                            <a href="#" target="_blank">17/25,2nd Floor, Griffith Main Rd, Essapallavaram, Pallavaram, Chennai, Tamil Nadu 600043</a>
                        </li>
                        <li>
                            <i class='bx bx-phone-call'></i>
                            <a href="tel:+919884668421">+91 98846 68421</a>
                        </li>
                        <li>
                            <i class='bx bx-envelope'></i>
                            <a href="mailto:info@zeptoware.in">info@zeptoware.in</a>
                        </li>
                    </ul>
                    <ul class="social-link">
                        <li><a  class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="https://x.com/zeptowaresystem?s=11" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="https://www.instagram.com/zeptowaresystem?igsh=bnloMWNndTRxaGhn" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="https://www.linkedin.com/company/zeptoware-system-private-limited/" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Support</h3>
                    <ul class="support-link">
                        <li><a routerLink="/">Join Now</a></li>
                        <li><a routerLink="/">About Us</a></li>
                        <li><a routerLink="/">Contact</a></li>
                        <li><a routerLink="/">Career</a></li>
                        <li><a routerLink="/">Privacy Policy</a></li>

                        
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Courses</h3>
                    <ul class="useful-link">
                        <li><a routerLink="/FullStackDevelopment">Full stack development</a></li>
                        <li><a routerLink="/databaseDevelopment">Database Development</a></li>
                        <li><a routerLink="/digitalMarketing">Digital marketing</a></li>
                        <li><a routerLink="/searchEngineOptimization">Search Engine Optimization</a></li>
                        <li><a routerLink="/mobileAppDevelopment">Mobile Application</a></li>
                        <li><a routerLink="/softwareTesting">Software Testing</a></li>
                        <li><a routerLink="/Angular">Angular</a></li>
                        <li><a routerLink="/React">React Js</a></li>
                        <li><a routerLink="/Java">Java Programming</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Newsletter</h3>
                    <div class="newsletter-box">
                        <p>To get the latest news and latest updates from us.</p>
                        <form class="newsletter-form">
                            <label>Your e-mail address:</label>
                            <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL">
                            <button type="submit">Subscribe</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-bottom-area">
        <div class="container">
            <!-- <div class="logo">
                <a routerLink="/" class="d-inline-block"><img src="assets/img/zepto.png" alt="image"></a>
            </div> -->
            <p>ZeptoWare System Private LTD</p>
        </div>
    </div>
</footer>

<ngx-scrolltop></ngx-scrolltop>