<app-header-style-one></app-header-style-one>

<div class="page-title-area item-bg1">
    <div class="container ">
        <div class="page-title-content  ">
            
            <h2>About Us</h2>
        </div>
    </div>
</div>

<div class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/courses/about.jpg" class="shadow" alt="image">
                    
                    
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">About Us</span>
                    <h2>Our mission</h2>
                    <p>Our mission is to empower individuals with practical skills and knowledge necessary to excel in their professional careers and personal development. We aim to provide high-quality, accessible training programs that bridge the gap between theoretical learning and practical application.</p>
                   
                    <h2>Vision</h2>
                    <p>Empowering individuals through trans-formative education, enabling them to thrive in a dynamic and competitive professional landscape.</p>

                    
                        <h5><i class='bx bx-planet'></i>Courses Offered</h5>
                        
                            <div class="about-text">
                                
                                <ul  class="features-list text-start">
                                    <li><i class='bx bx-check'></i> <span>Full Stack Development</span></li>
                                    <li><i class='bx bx-check'></i> Database Development</li>
                                    <li><i class='bx bx-check'></i>Digital Marketing</li>
                                    <li><i class='bx bx-check'></i>SEO</li>
                                    <li><i class='bx bx-check'></i>Mobile App Development</li>
                                    <li><i class='bx bx-check'></i>Software Testing</li>
                                </ul>
                            </div>
                        
                   
                </div>
            </div>
        </div>
        <div class="about-inner-area">
            <div class="row ">
                <h2 >Training Approach</h2>
            </div>
            <div class="row mt-4">
               
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>Experiential Learning</h3>
                        <p>Hands-on exercises, case studies, and real-world projects.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>Industry-Relevant Curriculum</h3>
                        <p>Constantly updated to align with current trends and best practices.</p>
                        
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                    <div class="about-text">
                        <h3>Expert Instruction</h3>
                        <p>Experienced industry professionals and certified instructors.</p>
                        
                    </div>
                </div>
                
            </div>
            <div class="row mt-4">
                <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                    <div class="about-text">
                        <h3>Flexible Learning Formats</h3>
                        <p>Offering in-person classes, online sessions, and self-paced modules.</p>
                        
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                    <div class="about-text">
                        <h3>Career Support</h3>
                        <p>Providing career counselling, job placement assistance, and networking opportunities for participants.</p>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container">
    <div class="page-title-content">
        <div >
            <h3 class="text-center">Zeptoware Training Institute Frequently Asked Question(FAQ)</h3>
            <div class="tabs-container mt-5">
                <div class="pane" id="tab1" >
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What is the mission of Zeptoware Training Institute?</h3>
                                <p><strong>A:</strong> Zeptoware Training Institute is dedicated to providing high-quality, industry-relevant education and training to empower individuals with the skills needed to succeed in the rapidly evolving fields of technology and business.</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: Who are the instructors at Zeptoware Training Institute?</h3>
                                <p><strong>A:</strong> The instructors at Zeptoware Training Institute are experienced professionals and experts in their respective fields. They bring practical insights and real-world knowledge to the classroom, ensuring that students receive valuable and up-to-date information.</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: How is the curriculum developed at Zeptoware Training Institute?</h3>
                                <p><strong>A:</strong> The curriculum is thoughtfully designed at Zeptoware Training Institute to align with industry trends and demands. It undergoes regular updates to incorporate the latest technologies and best practices, ensuring that students are well-prepared for the workforce.</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What facilities and resources are available for students at Zeptoware Training Institute?</h3>
                                <p><strong>A:</strong> Zeptoware Training Institute provides state-of-the-art facilities, equipped with the latest technologies to facilitate effective learning. Additionally, students have access to online resources, libraries, and collaboration spaces to enhance their educational experience.</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: How does Zeptoware Training Institute support student success beyond the classroom?</h3>
                                <p><strong>A:</strong> Zeptoware Training Institute is committed to the success of its students. Beyond classroom instruction, the institute offers career counseling, placement assistance, networking opportunities, and a supportive community to help students achieve their professional goals.</p>
                            </div>
                        </div>
                        
                       
                    </div>
                </div>
                
            
            </div>
        </div>
    </div>
</div>

<!-- <div class="story-area ptb-100">
    <app-our-story></app-our-story>
</div>

<div class="funfacts-area">
    <div class="container">
        <div class="funfacts-inner">
            <app-funfacts></app-funfacts>
        </div>
    </div>
</div>

<div class="values-area ptb-100">
    <app-our-values></app-our-values>
</div> -->
<!-- 
<div class="instructor-area pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Make Connections</span>
            <h2>Team of Instructors</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut nisi ut aliquip ex ea.</p>
        </div>
        <app-instructors-style-two></app-instructors-style-two>
    </div>
</div> -->

<!-- <div class="partner-area bg-color ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Our Company & Partners</h2>
        </div>
        <app-partner-style-one></app-partner-style-one>
    </div>
</div>

<div class="testimonials-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Testimonials</span>
            <h2>What Students Says</h2>
        </div>
        <app-feedback-style-two></app-feedback-style-two>
    </div>
</div> -->

<div class="become-instructor-partner-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="become-instructor-partner-content bg-color">
                    <h2>Looking for the Best Software IT Training Institute in Chennai?</h2>
                    <p>
                        Unlock your potential with the best software IT training institute in Chennai, 
                        offering unparalleled courses and industry expertise.</p>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="become-instructor-partner-image bg-image1">
                    <img src="assets/img/become-instructor.jpg" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="become-instructor-partner-image bg-image2">
                    <img src="assets/img/become-partner.jpg" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="become-instructor-partner-content">
                    <h2>Navigating the Path to Future Career Growth</h2>
                    <p>Unlock your creativity and shape a promising career path for a brighter future. Harness the power of creativity to fuel your career growth and achieve your dreams.</p>
                </div>
            </div>
        </div>
    </div>
</div>
