import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
    selector: 'app-blog',
    templateUrl: './blog.component.html',
    styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

    constructor( 
		private titleService: Title,  
		 private metaTagService: Meta) {
		
	   }
	 
	   ngOnInit(): void {
		 this.titleService.setTitle("Example of SEO Meta Tag using Angular");  
		 
		 this.metaTagService.addTags([  
		   { name: 'keywords', content: 'Angular SEO Title, Meta Description, Meta Keyword Example' },  
		   { name: 'robots', content: 'index, follow' },  
		   { name: 'writer', content: 'John Smith' },  
		   { charset: 'UTF-8' }  
		 ]);
	   }


}