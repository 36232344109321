<app-header-style-one></app-header-style-one>

<div class="page-title-area item-bg4">
    <div class="container ">
        <div class="page-title-content  ">
            
            <h2>Services</h2>
        </div>
    </div>
</div>


<div class="courses-categories-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <!-- <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-categories-courses-item bg1 mb-30">
                    <div class="icon">
                        <i class='bx bx-code-alt'></i>
                    </div>
                    <h3>Web Development</h3>
                    <span>60 Courses</span>
                    <a routerLink="/" class="learn-more-btn">Learn More <i class='bx bx-book-reader'></i></a>
                    <a routerLink="/" class="link-btn"></a>
                </div>
            </div> -->
            <!-- <a routerLink="/FullStackDevelopment" class="learn-more-btn">Learn More <i class='bx bx-book-reader'></i></a> -->
            <div class="col-lg-3 col-md-4 col-sm-6"  >
                <div class="single-categories-courses-item bg2 mb-30"  routerLink="/SoftwareTraining">
                    <div class="icon">
                        <i class='bx bx-camera'></i>
                    </div>
                    <h3 >Software Training </h3>
                    <span>21 Courses</span>
                    <!-- <a  class="learn-more-btn">Learn More <i class='bx bx-book-reader'></i></a> -->
                </div>
            </div>
           
            <div class="col-lg-3 col-md-4 col-sm-6" routerLink="/RealTimeExperience">
                <div class="single-categories-courses-item bg4 mb-30">
                    <div class="icon">
                        <i class='bx bxs-flag-checkered'></i>
                    </div>
                    <h3>Real Time Project Experience</h3>
                    <span>99 Courses</span>
                    <!-- <a  class="learn-more-btn">Learn More <i class='bx bx-book-reader'></i></a> -->
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6" routerLink="/SoftwareDevelopment">
                <div class="single-categories-courses-item bg5 mb-30">
                    <div class="icon">
                        <i class='bx bx-health'></i>
                    </div>
                    <h3>Software Development</h3>
                    <span>21 Courses</span>
                    <!-- <a class="learn-more-btn">Learn More <i class='bx bx-book-reader'></i></a> -->
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6" routerLink="/ProjectInternship">
                <div class="single-categories-courses-item bg6 mb-30">
                    <div class="icon">
                        <i class='bx bx-line-chart'></i>
                    </div>
                    <h3>Project Internship</h3>
                    <span>49 Courses</span>
                    <!-- <a  class="learn-more-btn">Learn More <i class='bx bx-book-reader'></i></a> -->
                </div>
            </div>
            <div class="col-12 ">
                <div class="row text-center">
                    <div class="col-lg-3 col-md-4 col-sm-6 " routerLink="/InplantTraining">
                        <div class="single-categories-courses-item bg9 mb-30">
                            <div class="icon">
                                <i class='bx bx-bar-chart-alt-2'></i>
                            </div>
                            <h3>Inplant Training</h3>
                            <span>21 Courses</span>
                            <!-- <a  class="learn-more-btn">Learn More <i class='bx bx-book-reader'></i></a> -->
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6" routerLink="/IndustrialVisit">
                        <div class="single-categories-courses-item bg10 mb-30">
                            <div class="icon">
                                <i class='bx bxs-drink'></i>
                            </div>
                            <h3>Industrial Visit</h3>
                            <span>12 Courses</span>
                            <!-- <a  class="learn-more-btn">Learn More <i class='bx bx-book-reader'></i></a> -->
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 " routerLink="/ManPowerConsultancy">
                        <div class="single-categories-courses-item bg11 mb-30">
                            <div class="icon">
                                <i class='bx bx-bar-chart-alt-2'></i>
                            </div>
                            <h3>Man Power Consultancy</h3>
                            <span>21 Courses</span>
                            <!-- <a  class="learn-more-btn">Learn More <i class='bx bx-book-reader'></i></a> -->
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 " routerLink="/Placement">
                        <div class="single-categories-courses-item bg12 mb-30">
                            <div class="icon">
                                <i class='bx bx-health'></i>
                            </div>
                            <h3>Placement</h3>
                            <span>21 Courses</span>
                            <!-- <a  class="learn-more-btn">Learn More <i class='bx bx-book-reader'></i></a> -->
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</div>


