<app-header-style-one></app-header-style-one>

<div class="page-title-area item-bg3">
    <div class="container">
        <div class="page-title-content">
           
            <h2>All Courses</h2>
        </div>
    </div>
</div>

<div class="courses-categories-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <!-- <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-categories-courses-item bg1 mb-30">
                    <div class="icon">
                        <i class='bx bx-code-alt'></i>
                    </div>
                    <h3>Web Development</h3>
                    <span>60 Courses</span>
                    <a routerLink="/" class="learn-more-btn">Learn More <i class='bx bx-book-reader'></i></a>
                    <a routerLink="/" class="link-btn"></a>
                </div>
            </div> -->
            <!-- <a routerLink="/FullStackDevelopment" class="learn-more-btn">Learn More <i class='bx bx-book-reader'></i></a> -->
            <div class="col-lg-3 col-md-4 col-sm-6" routerLink="/FullStackDevelopment">
                <div class="single-categories-courses-item bg2 mb-30"  >
                    <div class="icon">
                        <i class='bx bx-camera'></i>
                    </div>
                    <h3 >Full Stack Development </h3>
                    <span>21 Courses</span>
                    <!-- <a  class="learn-more-btn">Learn More <i class='bx bx-book-reader'></i></a> -->
                </div>
            </div>
           
            <div class="col-lg-3 col-md-4 col-sm-6" routerLink="/databaseDevelopment">
                <div class="single-categories-courses-item bg4 mb-30">
                    <div class="icon">
                        <i class='bx bxs-flag-checkered'></i>
                    </div>
                    <h3>Database Development</h3>
                    <span>99 Courses</span>
                    <!-- <a  class="learn-more-btn">Learn More <i class='bx bx-book-reader'></i></a> -->
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6" routerLink="/digitalMarketing">
                <div class="single-categories-courses-item bg5 mb-30">
                    <div class="icon">
                        <i class='bx bx-health'></i>
                    </div>
                    <h3>Digital marketing</h3>
                    <span>21 Courses</span>
                    <!-- <a class="learn-more-btn">Learn More <i class='bx bx-book-reader'></i></a> -->
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6" routerLink="/searchEngineOptimization">
                <div class="single-categories-courses-item bg6 mb-30">
                    <div class="icon">
                        <i class='bx bx-line-chart'></i>
                    </div>
                    <h3>Search Engine Optimization</h3>
                    <span>49 Courses</span>
                    <!-- <a  class="learn-more-btn">Learn More <i class='bx bx-book-reader'></i></a> -->
                </div>
            </div>
            <div class="col-12 ">
                <div class="row text-center">
                    <div class="col-lg-3 col-md-4 col-sm-6 " routerLink="/mobileAppDevelopment">
                        <div class="single-categories-courses-item bg7 mb-30">
                            <div class="icon">
                                <i class='bx bx-bar-chart-alt-2'></i>
                            </div>
                            <h3>Mobile Application</h3>
                            <span>21 Courses</span>
                            <!-- <a  class="learn-more-btn">Learn More <i class='bx bx-book-reader'></i></a> -->
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6" routerLink="/softwareTesting">
                        <div class="single-categories-courses-item bg13 mb-30">
                            <div class="icon">
                                <i class='bx bxs-drink'></i>
                            </div>
                            <h3>Angular</h3>
                            <span>12 Courses</span>
                            <!-- <a  class="learn-more-btn">Learn More <i class='bx bx-book-reader'></i></a> -->
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6" routerLink="/softwareTesting">
                        <div class="single-categories-courses-item bg14 mb-30">
                            <div class="icon">
                                <i class='bx bxs-drink'></i>
                            </div>
                            <h3>React Js</h3>
                            <span>12 Courses</span>
                            <!-- <a  class="learn-more-btn">Learn More <i class='bx bx-book-reader'></i></a> -->
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6" routerLink="/softwareTesting">
                        <div class="single-categories-courses-item bg15 mb-30">
                            <div class="icon">
                                <i class='bx bxs-drink'></i>
                            </div>
                            <h3>Java Programming</h3>
                            <span>12 Courses</span>
                            <!-- <a  class="learn-more-btn">Learn More <i class='bx bx-book-reader'></i></a> -->
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</div>

<div class="become-instructor-partner-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="become-instructor-partner-content bg-color">
                    <h2>Looking for the Best Software IT Training Institute in Chennai?</h2>
                    <p>
                        Unlock your potential with the best software IT training institute in Chennai, 
                        offering unparalleled courses and industry expertise.</p>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="become-instructor-partner-image bg-image1">
                    <img src="assets/img/become-instructor.jpg" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="become-instructor-partner-image bg-image2">
                    <img src="assets/img/become-partner.jpg" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="become-instructor-partner-content">
                    <h2>Navigating the Path to Future Career Growth</h2>
                    <p>Unlock your creativity and shape a promising career path for a brighter future. Harness the power of creativity to fuel your career growth and achieve your dreams.</p>
                </div>
            </div>
        </div>
    </div>
</div>