<app-header-style-one></app-header-style-one>

<div class="page-title-area item-bg5">
    <div class="container">
        <div class="page-title-content">
           
        </div>
    </div>
</div>

<div class="container mt-3">
    <div>
        <h2>ZEPTOWARE INPLANT & INTERNSHIP TRAINING INSTITUTE IN CHENNAI</h2>
        <div class="about-text">
                                
            <ul  class="features-list text-start">
                <li><i class='bx bx-check'></i>  Learn the Technology by Being a Real-Time Leader at Precise Times</li>
                <li><i class='bx bx-check'></i>  Process-oriented training using creative, ethical methods and placement support.</li>
                <li><i class='bx bx-check'></i> With 100% real-time scenario basis, professional-based training is provided </li>
                <li><i class='bx bx-check'></i> On Process Training Techniques Offered by Professional People              </li>
                <li><i class='bx bx-check'></i> Employing recapture technology, framework-based training provides results which are process-oriented                </li>
                <li><i class='bx bx-check'></i> Collaborative Real-Time Projects Experience and an opportunity to collaborate on projects in real time                </li>
                <li><i class='bx bx-check'></i>  Good course fee which involves free materials and software installation     </li>

            </ul>
        </div>
        <div class="row mt-4 mb-4">
               
            <div class="col-lg-4 col-md-6 col-sm-6 ">
                <div class="about-text card shadow p-4" style="border: none;">
                    <p class="text-center "><b class="h3 " >IPT</b></p>
                    <h3>INPLANT TRAINING</h3>
                    <ul  class="features-list text-start">
                        <li><i class='bx bx-check'></i>  Research & Development Company  </li>
                        <li><i class='bx bx-check'></i> Expert Technical Staff for Software Development Field</li>
                        <li><i class='bx bx-check'></i> Real Time Practical Training </li>
                        <li><i class='bx bx-check'></i> Certification Training Certificate</li>
                        <li><i class='bx bx-check'></i>100% Job Guarantee   </li>
                        
                    </ul>
                    <a   data-bs-toggle="modal" data-bs-target="#exampleModal1" class="default-btn mt-3 btn"><i class='bx bx-move-horizontal icon-arrow before'></i><span class="label">Apply Now</span><i class="bx bx-move-horizontal icon-arrow after"></i></a>
                </div>
                

            </div>
            <div class="col-lg-4 col-md-6 col-sm-6  ">
                <div class="about-text card shadow p-4" style="border: none;">
                    <p class="text-center "><b class="h3 " >ITT</b></p>
                    <h3>INTERSHIP TRAINING</h3>
                    <ul  class="features-list text-start">
                        <li><i class='bx bx-check'></i>  Industry Standard          </li>
                        <li><i class='bx bx-check'></i>Certification Internship Certificate
                        </li>
                        <li><i class='bx bx-check'></i> Expert Technical Staff for Software Development Field </li>
                        <li><i class='bx bx-check'></i>Work on Real Time Project Training</li>
                        <li><i class='bx bx-check'></i>100% Job Guarantee   </li>
                        
                    </ul>
                    <a    data-bs-toggle="modal" data-bs-target="#exampleModal1" class="default-btn mt-3 btn"><i class='bx bx-move-horizontal icon-arrow before'></i><span class="label">Apply Now</span><i class="bx bx-move-horizontal icon-arrow after"></i></a>
                </div>
               
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6  ">
                <div class="about-text card shadow p-4" style="border: none;">
                    <p class="text-center"><b class="h3 p-3" >Courses</b></p>
                    <h3>ZEPTOWARE</h3>
                    <ul  class="features-list text-start p-2">
                        <li><i class='bx bx-check'></i>  Full Stack Development         </li>
                        <li><i class='bx bx-check'></i>Software Testing</li>
                        <li><i class='bx bx-check'></i> Database with SQL </li>
                        <li><i class='bx bx-check'></i>Mobile Application Development</li>
                        <li><i class='bx bx-check'></i>Digital Marketing   </li>
                        
                    </ul>
                    
                    <a   data-bs-toggle="modal" data-bs-target="#exampleModal1" class="default-btn mt-3 btn"><i class='bx bx-move-horizontal icon-arrow before'></i><span class="label">Apply Now</span><i class="bx bx-move-horizontal icon-arrow after"></i></a>


                    
                </div>
               
            </div>
            
        </div>
    </div>
</div>




 <!-- Modal -->
 <div class="modal fade " id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog "> 
      <div class="modal-content w-100">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">Apply for a Internship</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <div class="start-free-trial-content">
                <h3>Personal Information</h3>
                <!-- <span>Already have an ID? <a routerLink="/login">Sign In</a></span> -->
                <form [formGroup]="StudentDetails" (ngSubmit)="GetStudentDetails()" class="start-free-trial-form">
                    <div class="row justify-content-center">
                      <div class="col-12 col-xl-6 col-md-6 col-lg-6">
                        <div class="form-group">
                          <label>Full Name<span class="text-danger">*</span></label>
                          <input type="text" class="form-control" formControlName="p_name">
                          <div *ngIf="StudentDetails.get('p_name').invalid && (StudentDetails.get('p_name').dirty || StudentDetails.get('p_name').touched)">
                            <div *ngIf="StudentDetails.get('p_name').errors.required">
                             <span class="text-danger">Full Name is required.
                           </span>  </div>
                          </div>
                        </div>
                      </div>
                        <div class="col-12 col-xl-6 col-md-6 col-lg-6">
                            <div class="form-group">
                                <label>Mobile Number<span class="text-danger">*</span></label>
                                <input type="number" class="form-control" formControlName="p_mbl_no">
                                <div *ngIf="StudentDetails.get('p_mbl_no').invalid && (StudentDetails.get('p_mbl_no').dirty || StudentDetails.get('p_mbl_no').touched)">
                                    <div *ngIf="StudentDetails.get('p_mbl_no').errors.required">
                                        <span class="text-danger">Mobile Number id is required.
                                        </span>
                                        
                                    </div>
                                  </div>
                            </div>
                        </div>
                        <div class="col-12 col-xl-6 col-md-6 col-lg-6">
                            <div class="form-group">
                                <label>Email<span class="text-danger">*</span></label>
                                <input type="email" class="form-control" formControlName="p_email">

                                <div *ngIf="StudentDetails.get('p_email').invalid && (StudentDetails.get('p_email').dirty || StudentDetails.get('p_email').touched)">
                                    <div *ngIf="StudentDetails.get('p_email').errors.required">
                                        <span class="text-danger">Email is required.
                                        </span>
                                        
                                    </div>
                                  </div>
                            </div>
                        </div>
                        
                        <div class="col-12 col-xl-6 col-md-6 col-lg-6">
                            <div class="form-group">
                                <label>Courses<span class="text-danger">*</span></label>
                                <select formControlName="p_course" class="form-control custom-select" >
                                    <option value="FullStackDevelopment" selected>Full Stack Development</option>
                                    <option value="DatabaseDevelopment">Database Development</option>
                                    <option value="Digitalmarketing">Digital marketing</option>
                                    <option value="SearchEngineOptimization">Search Engine Optimization</option>
                                    <option value="MobileApplication">Mobile Application</option>
                                    <option value="SoftwareTesting">Software Testing</option>
                                </select>
                                <div *ngIf="StudentDetails.get('p_course').invalid && (StudentDetails.get('p_course').dirty || StudentDetails.get('p_course').touched)">
                                    <div *ngIf="StudentDetails.get('p_course').errors.required">
                                        <span class="text-danger">City name is required.
                                        </span>
                                        
                                    </div>
                                  </div>
                            </div>
                        </div>
                        <div class="col-12  ">
                            <div class="form-group">
                                <label>Remarks<span class="text-danger">*</span></label>
                                <input type="text" class="form-control height" formControlName="p_remarks">
                                <div *ngIf="StudentDetails.get('p_remarks').invalid && (StudentDetails.get('p_remarks').dirty || StudentDetails.get('p_remarks').touched)">
                                    <div *ngIf="StudentDetails.get('p_remarks').errors.required">
                                        <span class="text-danger">Remarks  is required.
                                        </span>
                                        
                                    </div>
                                  </div>
                            </div>
                        </div>
                       
                             

                
                       
                        <div class="col-lg-12 col-md-12 text-center">
                            <div class="">
                                <button class="btn" type="submit" >Register</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div>
      </div> -->
    </div>
  </div>
