<header class="navbar-area navbar-style-three " [ngClass]="{'sticky': isSticky}">
    <div class="top-header top-header-style-four">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-8">
                    <ul class="top-header-contact-info">
                        <li>
                            Call: 
                            <a href="tel:+919884668421">+91 98846 68421</a>
                        </li>
                    </ul>
                    <div class="top-header-social">
                        <span>Follow us:</span>
                        <a  target="_blank"><i class='bx bxl-facebook'></i></a>
                        <a href="https://x.com/zeptowaresystem?s=11" target="_blank"><i class='bx bxl-twitter'></i></a>
                        <a href="https://www.linkedin.com/company/zeptoware-system-private-limited/" target="_blank"><i class='bx bxl-linkedin'></i></a>
                        <a href="https://www.instagram.com/zeptowaresystem?igsh=bnloMWNndTRxaGhn" target="_blank"><i class='bx bxl-instagram'></i></a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4">
                    <ul class="top-header-login-register">
                        <li class="btn bg-color" data-bs-toggle="modal" data-bs-target="#exampleModal22"><a ><i class='bx bx-log-in-circle'></i> Apply For an Internship     </a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <!-- Button trigger modal -->
<!-- Button trigger modal -->


    <div class="navbar-area navbar-style-three" >
        <div class="container">
            <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
                <a  class="navbar-brand responsivelogoimage" routerLink="/"><img class="imgofzepto" src="assets/img/zepto.png"  alt="logo"  ></a>
                <button class="navbar-toggler" type="button" (click)="toggleClass()">
                    <span class="burger-menu">   
                        <span class="top-bar"></span>
                        <span class="middle-bar"></span>
                        <span class="bottom-bar"></span>
                    </span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item hover"><a routerLink="/home" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home </a>
                            <!-- <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Online Education Portal</a></li>
                                <li class="nav-item"><a routerLink="/course-portal-online" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Course Portal Online</a></li>
                                <li class="nav-item"><a routerLink="/distant-learning" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Distant Learning</a></li>
                                <li class="nav-item"><a href="#" class="nav-link">Popular Home Demos <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/modern-teaching" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Modern Teaching</a></li>
                                        <li class="nav-item"><a routerLink="/online-schooling" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Online Schooling</a></li>
                                        <li class="nav-item"><a routerLink="/learning-school-online" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Learning School Online</a></li>
                                        <li class="nav-item"><a routerLink="/remote-training-online" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Remote Training Online</a></li>
                                    </ul>
                                </li>
                                <li class="nav-item"><a href="#" class="nav-link">New Home Demos <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/business-coaching" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Business Coaching</a></li>
                                        <li class="nav-item"><a routerLink="/health-coaching" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Health Coaching</a></li>
                                        <li class="nav-item"><a routerLink="/language-school" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Language School</a></li>
                                        <li class="nav-item"><a routerLink="/gym-coaching" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Gym Coaching</a></li>
                                        <li class="nav-item"><a routerLink="/online-motivation-course" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Online Motivation Course</a></li>
                                        <li class="nav-item"><a routerLink="/online-education-course" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Online Education Course</a></li>
                                        <li class="nav-item"><a routerLink="/online-training-course" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Online Training Course</a></li>
                                    </ul>
                                </li>
                            </ul> -->
                        </li>
                        <li class="nav-item"><a routerLink="/AboutUs" class="nav-link"  routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About Us</a>
                            <!-- <ul class="dropdown-menu">
                                <li class="nav-item"><a href="#" class="nav-link">About <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/AboutUs" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About Style 1</a></li>
                                        <li class="nav-item"><a routerLink="/about-style-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About Style 2</a></li>
                                    </ul>
                                </li>
                                <li class="nav-item"><a href="#" class="nav-link">Instructor <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/instructor-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Instructor Style 1</a></li>
                                        <li class="nav-item"><a routerLink="/instructor-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Instructor Style 2</a></li>
                                        <li class="nav-item"><a routerLink="/instructor-3" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Instructor Style 3</a></li>
                                        <li class="nav-item"><a routerLink="/single-instructor" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Instructor Details</a></li>
                                    </ul>
                                </li>
                                <li class="nav-item"><a routerLink="/gallery" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Gallery</a></li>
                                <li class="nav-item"><a href="#" class="nav-link">Events <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/events" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Events</a></li>
                                        <li class="nav-item"><a routerLink="/single-events" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Events Details</a></li>
                                    </ul>
                                </li>
                                <li class="nav-item"><a routerLink="/pricing" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Pricing</a></li>
                                <li class="nav-item"><a routerLink="/feedback" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Feedback</a></li>
                                <li class="nav-item"><a routerLink="/partner" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Partner</a></li>
                                <li class="nav-item"><a routerLink="/login" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Log In</a></li>
                                <li class="nav-item"><a routerLink="/register" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Register</a></li>
                                <li class="nav-item"><a routerLink="/faqs" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">FAQ's</a></li>
                                <li class="nav-item"><a routerLink="/error-404" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">404 Error Page</a></li>
                                <li class="nav-item"><a routerLink="/coming-soon" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Coming Soon</a></li>
                            </ul> -->
                        </li>


                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Services <i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                                  <li class="nav-item"><a routerLink="/Services" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">All Services</a></li>
                                <li class="nav-item"><a routerLink="/SoftwareTraining" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Software Training</a></li>
                                <li class="nav-item"><a routerLink="/RealTimeExperience" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Real Time Projects Experiance</a></li>
                                <li class="nav-item"><a routerLink="/ProjectInternship" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Project Internship</a></li>
                                <li class="nav-item"><a routerLink="/InplantTraining" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Inplant Training</a></li>
                                <li class="nav-item"><a routerLink="/IndustrialVisit" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Industrial Visit </a></li>
                                <li class="nav-item"><a routerLink="/SoftwareDevelopment" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Software Development</a></li>
                                
                               
                                <li class="nav-item"><a routerLink="/ManPowerConsultancy" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Man Power Consultancy</a></li>
                                <li class="nav-item"><a routerLink="/Placement" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Placement Activities</a></li>
                               
                                   
                       

                                <!-- <li class="nav-item"><a routerLink="/Java" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Java Programming</a></li> -->
                                <!-- <li class="nav-item"><a routerLink="/courses-list" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Courses List</a></li>
                                <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Courses Grid Style 1 <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/courses-2-columns-style-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">2 Columns</a></li>
                                        <li class="nav-item"><a routerLink="/courses-3-columns-style-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">3 Columns</a></li>
                                        <li class="nav-item"><a routerLink="/courses-4-columns-style-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">4 Columns Full Width</a></li>
                                    </ul>
                                </li>
                                <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Courses Grid Style 2 <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/courses-2-columns-style-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">2 Columns</a></li>
                                        <li class="nav-item"><a routerLink="/courses-3-columns-style-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">3 Columns</a></li>
                                        <li class="nav-item"><a routerLink="/courses-4-columns-style-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">4 Columns Full Width</a></li>
                                    </ul>
                                </li>
                                <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Courses Grid Style 3 <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/courses-2-columns-style-3" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">2 Columns</a></li>
                                        <li class="nav-item"><a routerLink="/courses-3-columns-style-3" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">3 Columns</a></li>
                                        <li class="nav-item"><a routerLink="/courses-4-columns-style-3" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">4 Columns Full Width</a></li>
                                    </ul>
                                </li>
                                <li class="nav-item"><a routerLink="/single-courses" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Courses Details</a></li>
                                <li class="nav-item"><a routerLink="/my-dashboard" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">My Dashboard</a></li> -->
                            </ul>
                        
                        </li>


                        <li class="nav-item "><a href="javascript:void(0)" class="nav-link">Courses <i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu length">
                                <li class="nav-item"><a routerLink="/Courses" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">ALL Courses</a></li>
                                <li class="nav-item"><a routerLink="/searchEngineOptimization" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Search Engine Optimization <span class=" fa-solid fa-download" (click)="SEO()"></span></a></li>
                                <li class="nav-item"><a routerLink="/digitalMarketing" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Digital Marketing <span class="mx-2 fa-solid fa-download" (click)="digitalMarketing()"></span></a></li>

                               
                              
                                <li class="nav-item"><a routerLink="/softwareTesting" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Software Testing <span class="mx-2 fa-solid fa-download" (click)="softwareTesting()"></span></a></li>
                               
                                <li class="nav-item"><a  class="nav-link">UI Development <i class='bx bx-chevron-right'></i>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/Angular"  class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Angular <span class=" fa-solid fa-download"></span></a></li>
                                        <li class="nav-item"><a routerLink="/React" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">React Js <span class=" fa-solid fa-download"></span></a></li>
                                       
                                       
                                    </ul>
                                </a></li>
                                <li class="nav-item"><a routerLink="/FullStackDevelopment" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Full Stack Development <span class="mx-2 fa-solid fa-download" (click)="FullStackDevelopment()"></span></a></li>

                                <li class="nav-item"><a routerLink="/mobileAppDevelopment" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Mobile Application <span class="mx-2 fa-solid fa-download" (click)="mobileAppDevelopment()"></span></a></li>
                               

                                <li class="nav-item"><a routerLink="/Java" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Java Programming <span class="mx-2 fa-solid fa-download"></span></a></li>
                                <li class="nav-item"><a routerLink="/databaseDevelopment" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Database Programming <span class="mx-2 fa-solid fa-download" (click)="Database()"></span></a></li> 
                                <!-- <li class="nav-item"><a routerLink="/courses-list" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Courses List</a></li>
                                <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Courses Grid Style 1 <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/courses-2-columns-style-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">2 Columns</a></li>
                                        <li class="nav-item"><a routerLink="/courses-3-columns-style-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">3 Columns</a></li>
                                        <li class="nav-item"><a routerLink="/courses-4-columns-style-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">4 Columns Full Width</a></li>
                                    </ul>
                                </li>
                                <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Courses Grid Style 2 <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/courses-2-columns-style-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">2 Columns</a></li>
                                        <li class="nav-item"><a routerLink="/courses-3-columns-style-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">3 Columns</a></li>
                                        <li class="nav-item"><a routerLink="/courses-4-columns-style-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">4 Columns Full Width</a></li>
                                    </ul>
                                </li>
                                <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Courses Grid Style 3 <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/courses-2-columns-style-3" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">2 Columns</a></li>
                                        <li class="nav-item"><a routerLink="/courses-3-columns-style-3" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">3 Columns</a></li>
                                        <li class="nav-item"><a routerLink="/courses-4-columns-style-3" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">4 Columns Full Width</a></li>
                                    </ul>
                                </li>
                                <li class="nav-item"><a routerLink="/single-courses" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Courses Details</a></li>
                                <li class="nav-item"><a routerLink="/my-dashboard" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">My Dashboard</a></li> -->
                            </ul>
                        </li>

                       

                        <li class="nav-item"><a routerLink="/careers" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Career </a>
                            <!-- <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/blog-style-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Grid</a></li>
                                <li class="nav-item"><a routerLink="/blog-style-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Right Sidebar</a></li>
                                <li class="nav-item"><a routerLink="/blog-style-3" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Grid Full Width</a></li>
                                <li class="nav-item"><a routerLink="/single-blog" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Details</a></li>
                            </ul> -->
                        </li>
                        <!-- <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Shop <i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/shop-grid" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Shop Grid</a></li>
                                <li class="nav-item"><a routerLink="/shop-grid-fullwidth" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Shop Grid FullWidth</a></li>
                                <li class="nav-item"><a routerLink="/single-products" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Products Details</a></li>
                                <li class="nav-item"><a routerLink="/cart" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Cart</a></li>
                                <li class="nav-item"><a routerLink="/checkout" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Checkout</a></li>
                            </ul>
                        </li> -->
                        

                        <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a></li>


                    

                    </ul>
                </div>
                <div class="others-option">
                    <!-- <div class="dropdown language-switcher d-inline-block" [class.active]="classApplied2">
                        <button class="dropdown-toggle" type="button" (click)="toggleClass2()">
                            <img src="assets/img/us-flag.jpg" class="shadow" alt="image">
                            <span>Eng <i class='bx bx-chevron-down'></i></span>
                        </button>
                        <div class="dropdown-menu">
                            <a href="#" class="dropdown-item d-flex align-items-center">
                                <img src="assets/img/germany-flag.jpg" class="shadow-sm" alt="flag">
                                <span>Ger</span>
                            </a>
                            <a href="#" class="dropdown-item d-flex align-items-center">
                                <img src="assets/img/france-flag.jpg" class="shadow-sm" alt="flag">
                                <span>Fre</span>
                            </a>
                            <a href="#" class="dropdown-item d-flex align-items-center">
                                <img src="assets/img/spain-flag.jpg" class="shadow-sm" alt="flag">
                                <span>Spa</span>
                            </a>
                            <a href="#" class="dropdown-item d-flex align-items-center">
                                <img src="assets/img/russia-flag.jpg" class="shadow-sm" alt="flag">
                                <span>Rus</span>
                            </a>
                            <a href="#" class="dropdown-item d-flex align-items-center">
                                <img src="assets/img/italy-flag.jpg" class="shadow-sm" alt="flag">
                                <span>Ita</span>
                            </a>
                        </div>
                    </div>
                    <a routerLink="/cart" class="cart-wrapper-btn d-inline-block">
                        <i class='bx bx-cart-alt'></i>
                        <span>01</span>
                    </a> -->
                    <div class="search-box d-inline-block">
                        <button class="btn" routerLink="/Internship">Apply Now</button>
                    </div>
                </div>
            </nav>
        </div>
    </div> 
</header>

<div class="search-overlay" [class.active]="classApplied3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-close" (click)="toggleClass3()">
                <span class="search-overlay-close-line"></span>
                <span class="search-overlay-close-line"></span>
            </div>
            <div class="search-overlay-form">
                <form>
                    <input type="text" class="input-search" placeholder="Search here...">
                    <button type="submit"><i class='bx bx-search-alt'></i></button>
                </form>
            </div>
        </div>
    </div>
</div>

  
  <!-- Modal -->
  <div class="modal fade " id="exampleModal22" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog "> 
      <div class="modal-content w-100">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">Apply for a Internship</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <div class="start-free-trial-content">
               
                <!-- <span>Already have an ID? <a routerLink="/login">Sign In</a></span> -->
                <form [formGroup]="StudentDetails1" (ngSubmit)="GetStudentDetails()" class="start-free-trial-form">
                    <div class="row justify-content-center">
                      <div class="col-12 col-xl-6 col-md-6 col-lg-6">
                        <div class="form-group">
                          <label>Full Name<span class="text-danger">*</span></label>
                          <input type="text" class="form-control" formControlName="p_name">
                          <div *ngIf="StudentDetails1.get('p_name').invalid && (StudentDetails1.get('p_name').dirty || StudentDetails1.get('p_name').touched)">
                            <div *ngIf="StudentDetails1.get('p_name').errors.required">
                             <span class="text-danger">Full Name is required.
                           </span>  </div>
                          </div>
                        </div>
                      </div>
                        <div class="col-12 col-xl-6 col-md-6 col-lg-6">
                            <div class="form-group">
                                <label>Mobile Number<span class="text-danger">*</span></label>
                                <input type="number" class="form-control" formControlName="p_mbl_no">
                                <div *ngIf="StudentDetails1.get('p_mbl_no').invalid && (StudentDetails1.get('p_mbl_no').dirty || StudentDetails1.get('p_mbl_no').touched)">
                                    <div *ngIf="StudentDetails1.get('p_mbl_no').errors.required">
                                        <span class="text-danger">Mobile Number id is required.
                                        </span>
                                        
                                    </div>
                                  </div>
                            </div>
                        </div>
                        <div class="col-12 col-xl-6 col-md-6 col-lg-6">
                            <div class="form-group">
                                <label>Email<span class="text-danger">*</span></label>
                                <input type="email" class="form-control" formControlName="p_email">

                                <div *ngIf="StudentDetails1.get('p_email').invalid && (StudentDetails1.get('p_email').dirty || StudentDetails1.get('p_email').touched)">
                                    <div *ngIf="StudentDetails1.get('p_email').errors.required">
                                        <span class="text-danger">Email is required.
                                        </span>
                                        
                                    </div>
                                  </div>
                            </div>
                        </div>
                        
                        <div class="col-12 col-xl-6 col-md-6 col-lg-6">
                            <div class="form-group">
                                <label>Courses<span class="text-danger">*</span></label>
                                <select formControlName="p_course" class="form-control custom-select" >
                                    <option value="FullStackDevelopment" selected>Full Stack Development</option>
                                    <option value="DatabaseDevelopment">Database Development</option>
                                    <option value="Digitalmarketing">Digital marketing</option>
                                    <option value="SearchEngineOptimization">Search Engine Optimization</option>
                                    <option value="MobileApplication">Mobile Application</option>
                                    <option value="SoftwareTesting">Software Testing</option>
                                </select>
                                <div *ngIf="StudentDetails1.get('p_course').invalid && (StudentDetails1.get('p_course').dirty || StudentDetails1.get('p_course').touched)">
                                    <div *ngIf="StudentDetails1.get('p_course').errors.required">
                                        <span class="text-danger">City name is required.
                                        </span>
                                        
                                    </div>
                                  </div>
                            </div>
                        </div>
                        <div class="col-12  ">
                            <div class="form-group">
                                <label>Remarks<span class="text-danger">*</span></label>
                                <input type="text" class="form-control height" formControlName="p_remarks">
                                <div *ngIf="StudentDetails1.get('p_remarks').invalid && (StudentDetails1.get('p_remarks').dirty || StudentDetails1.get('p_remarks').touched)">
                                    <div *ngIf="StudentDetails1.get('p_remarks').errors.required">
                                        <span class="text-danger">Remarks  is required.
                                        </span>
                                        
                                    </div>
                                  </div>
                            </div>
                        </div>
                       
                             

                
                       
                        <div class="col-lg-12 col-md-12 text-center">
                            <div class="">
                                <button class="btn" type="submit" >Register</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div>
      </div> -->
    </div>
  </div>
  </div>



