import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'; 
import { Observable } from 'rxjs';
interface ApiResponse {
  status: string;
  code: string;
  data: any; // Assuming 'data' is an array of 'Student' objects
}
interface Student {
  p_first_name : string;
  p_emailid : string;
  p_mobnum : string;
  p_city : string;
  p_state : string;
  p_district  : string;
  p_pincode  : string;
  gender  : string;
  p_institution_name  : string;
  p_ug_degree  : string;
  p_ug_dept  : string;
  p_ug_yoc  : string;
  p_course_name  : string;
  p_father_name  : string;
  p_others_yoc  : string;
  p_others_mrksprcnt  : string;
  upload_photo: string;

  // Add other properties here as needed
}
@Injectable({
  providedIn: 'root'
})
export class FormService {
  SERVER_URL: string = "http://65.0.1.125:81/zeptoware/";
   httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  private popupShownKey = 'popupShown';
  students: Student[] = [];
  constructor(private http: HttpClient) { }

  SentStudentFormDetais(data: any): Observable<any> {
    return this.http.post<any>('https://tkexpress.co.in/support_management/index.phpment/index.php/insert_requirement', data);
  }

  popupStudentDetails(data: any): Observable<any> {
    return this.http.post<any>('https://tkexpress.co.in/support_management/index.phpment/index.php/insert_o_enquiry', data);
  }

  shouldShowPopup(): boolean {
    return !sessionStorage.getItem(this.popupShownKey);
  }

  setPopupShown() {
    sessionStorage.setItem(this.popupShownKey, 'true');
  }
  StudentDetailsGet(id:any){
    return this.http.get<ApiResponse>(`https://tkexpress.co.in/support_management/index.phpment/index.php/get_o_registeration?id=${id}`)
  }

  public uploadFile(data) {
    let uploadURL = `${this.SERVER_URL}/upload_documents.php`;
    console.log('121',uploadURL, data , this.httpOptions)
    return this.http.post<any>(uploadURL, data,);
  }
  
}
