import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser'; 

@Component({
  selector: 'app-about-page-one',
  templateUrl: './about-page-one.component.html',
  styleUrls: ['./about-page-one.component.scss']
})
export class AboutPageOneComponent implements OnInit {

  constructor( 
   private titleService: Title,  
    private metaTagService: Meta) {
   
  }

  ngOnInit(): void {
    this.titleService.setTitle("Best Software Training Institutes in Chennai with placement- Zeptoware ");  
    
    this.metaTagService.addTags([  
      { name: 'keywords', content: 'Angular SEO Title, Meta Description, Meta Keyword Example' },  
      { name: 'robots', content: 'index, follow' },  
      { name: 'writer', content: 'John Smith' },  
      { charset: 'UTF-8' }  
    ]);
  }

}
