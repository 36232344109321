<app-header-style-one></app-header-style-one>
<div class="container p-3">
    <div class="row border border-dark p-3 mt-2 ">
        <div class="row text-center">
            <h2 class="text-center mb-3">INPLANT TRAINING</h2>
            <div class="col-lg-2 ">

            </div>
            <div class="col-lg-8">
                <div class="courses-details-image text-center">
                    <img src="assets/img2/inplant training.png" style="height: 50vh;" alt="image">
                </div>
            </div>    
        </div>  

        <div class="col-12">
            <p> Inplant training, often abbreviated as IPT, is a structured program designed to provide students with hands-on experience and practical exposure within an industrial or corporate setting. It serves as a bridge between theoretical knowledge gained in academic institutions and real-world application in industries. Inplant training offers students the opportunity to witness the functioning of an organization firsthand, understand industry practices, and gain insights into the day-to-day operations of various departments.</p>
            <p>Typically, inplant training programs are undertaken by students pursuing undergraduate or postgraduate degrees in engineering, technology, management, and other related fields. These programs are usually short-term, ranging from a few weeks to a couple of months, during which students work closely with professionals in their chosen field. They engage in tasks, projects, and assignments relevant to their area of study, allowing them to apply theoretical concepts in a practical setting.</p>
            <p>The objectives of inplant training vary depending on the industry and the specific requirements of the educational institution. However, common goals include:</p>
            <div class="about-text">
                                
                <ul  class="features-list text-start">
                    <li><i class='bx bx-check'></i><p>Practical Exposure: Providing students with hands-on experience to supplement their classroom learning and enhance their understanding of industry practices.</p></li>
                    <li><i class='bx bx-check'></i><p>Skill Development: Helping students develop technical skills, soft skills, and industry-specific competencies that are valuable for their future careers.</p></li>
                    <li><i class='bx bx-check'></i><p>Industry Insight: Exposing students to the organizational structure, culture, and operational dynamics of the industry they are interested in pursuing.</p></li>
                    <li><i class='bx bx-check'></i><p>Networking Opportunities: Facilitating interactions between students and professionals, enabling them to build valuable connections and gain insights into potential career paths.</p></li>
                    <li><i class='bx bx-check'></i><p>Career Readiness: Equipping students with the necessary skills, knowledge, and confidence to transition from academia to the professional world seamlessly.</p></li>
                    
                </ul>
            </div>
            <p>Inplant training programs are often conducted during semester breaks or summer vacations to minimize disruptions to students' regular academic schedules. Institutions may collaborate with industry partners to arrange these training opportunities, ensuring that students receive relevant and meaningful experiences that align with their academic goals and career aspirations.</p>
            <p>Overall, inplant training plays a vital role in bridging the gap between academic learning and practical application, preparing students for the challenges and opportunities they will encounter in their future careers.</p>
        </div>
    </div>    
</div>