import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../service/auth.service';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.scss']
})
export class AdminLoginComponent {
  username;
  password;
  errorMessage: string = '';
  name='Femtosoft@123';
  pass='Femtosoft@123';

  constructor(private authService: AuthService, private router: Router) { }

  login(): void {
   if(this.username==this.name && this.pass==this.password){
    sessionStorage.setItem('Admin', 'LoginSuccess');
    this.router.navigate(['/StudentDetails']);
    
   }
  }
}
