<div class="container-fluid">
    <div class="row d-flex justify-content-center align-items-center     h-100 ">
        <div class="card shadow col-12 col-xl-4 col-lg-4 col-md-4 p-4  heignt">
            <label>Admin User Name</label>
            <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1"><b class="bx bx-user"></b></span>
                <input type="text" class="form-control" placeholder="Enter Username" [(ngModel)]="username" required aria-label="Username" aria-describedby="basic-addon1">
              </div>
            <label>Password</label>
            <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1"><b class="bx bx-key"></b></span>
                <input type="text" class="form-control" placeholder="Enter Password" [(ngModel)]="password" required aria-label="Username" aria-describedby="basic-addon1">
              </div>
                <div class="row text-center mt-4">
                     <div class="col-12">
                    <button class="btn w-50" (click)="login()">Login</button>
                    </div>
                 </div>
        </div>
    </div>
</div>
