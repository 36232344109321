<app-header-style-one></app-header-style-one>
<div class="container p-3">
    <div class="row border border-dark p-3 mt-2 ">
        
        <div class="row text-center">
            <h2 class="text-center mb-3">INDUSTRIAL VISIT</h2>
            <div class="col-lg-2 ">

            </div>
            <div class="col-lg-8">
                <div class="courses-details-image text-center">
                    <img src="assets/img2/industrial visit.png" style="height: 50vh;" alt="image">
                </div>
            </div>    
        </div> 

        <div class="col-12">
            <p> An industrial visit, also referred to as an industrial tour or company visit, is a structured educational excursion that provides students with firsthand exposure to industrial environments, processes, and operations. It involves visiting manufacturing plants, factories, research facilities, or other industrial establishments relevant to the students' field of study. Industrial visits are commonly organized for students pursuing degrees in engineering, technology, management, and other related disciplines.</p>
            <p>The primary objectives of industrial visits are to:</p>
            <div class="about-text">
                                
                <ul  class="features-list text-start">
                    <li><i class='bx bx-check'></i><p>Enhance Learning: Industrial visits offer students practical insights into the application of theoretical concepts learned in the classroom. By observing real-world operations and processes, students can better understand the complexities and challenges of their chosen field.</p></li>

                    <li><i class='bx bx-check'></i><p>Industry Insight: Visiting industrial facilities allows students to gain a deeper understanding of various industries, including their organizational structures, production methods, quality control measures, safety protocols, and technological advancements. This firsthand exposure helps students grasp industry-specific practices and trends.</p></li>

                    <li><i class='bx bx-check'></i><p>Bridge Theory and Practice: Industrial visits bridge the gap between academic learning and practical application by showcasing how theoretical knowledge translates into real-world applications. Students can see how concepts such as engineering principles, management theories, and business strategies are implemented in industrial settings.</p></li>
                    <li><i class='bx bx-check'></i><p>Networking Opportunities: Industrial visits often provide students with opportunities to interact with industry professionals, engineers, managers, and other experts. These interactions allow students to ask questions, seek advice, and build valuable connections that may benefit their future careers.</p></li>

                    <li><i class='bx bx-check'></i><p>Career Exploration: Industrial visits expose students to different career paths and job roles within various industries. By witnessing firsthand the work environments and job responsibilities, students can make more informed decisions about their future career aspirations.</p></li>
                    
                </ul>
            </div>
            <p>Industrial visits are typically organized by educational institutions in collaboration with industry partners. They may be scheduled during semester breaks, academic terms, or as part of a curriculum requirement. The duration of industrial visits can vary, ranging from a few hours to several days, depending on the complexity of the industrial processes being observed and the objectives of the visit.</p>
            
        </div>
    </div>    
</div>