<app-header-style-one></app-header-style-one>


<div class="container border border-dark p-5 mb-4  ">
    <div class="row">
        <div class="col-12">
            <form [formGroup]="StudentDetails2" (ngSubmit)="GetStudentDetails()" class="start-free-trial-form ">
                
                <div class="row">
                    <div class="col-12">
                        <h2>Apply For Internship</h2>
                        <hr>
                        <h4>Personal Information</h4>
                        
                    </div>
                </div>
                
                <div class="row justify-content-center mt-xl-3">
                  <div class="col-lg-6 col-md-6  pb-4">
                    <div class="form-group">
                      <label>Full Name<span class="text-danger">*</span></label>
                      <input type="text" class=" form-control" formControlName="p_first_name" #name>
                      <div *ngIf="StudentDetails2.get('p_first_name').invalid && (StudentDetails2.get('p_first_name').dirty || StudentDetails2.get('p_first_name').touched)">
                        <div *ngIf="StudentDetails2.get('p_first_name').errors.required">
                         <span class="text-danger">Full Name is required.
                       </span>  </div>
                      </div>
                    </div>
                  </div>
                    <div class="col-lg-6 col-md-6 pb-4">
                        <div class="form-group">
                            <label>Email<span class="text-danger">*</span></label>
                            <input type="email" class=" form-control" formControlName="p_emailid" #email>
                            <div *ngIf="StudentDetails2.get('p_emailid').invalid && (StudentDetails2.get('p_emailid').dirty || StudentDetails2.get('p_emailid').touched)">
                                <div *ngIf="StudentDetails2.get('p_emailid').errors.required">
                                    <span class="text-danger">Email id is required.
                                    </span>
                                    
                                </div>
                              </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 pb-4">
                        <div class="form-group">
                            <label>Phone Number<span class="text-danger">*</span></label>
                            <input type="number" class=" form-control" formControlName="p_mobnum" #phoneNumber>
            
                            <div *ngIf="StudentDetails2.get('p_mobnum').invalid && (StudentDetails2.get('p_mobnum').dirty || StudentDetails2.get('p_mobnum').touched)">
                                <div *ngIf="StudentDetails2.get('p_mobnum').errors.required">
                                    <span class="text-danger">Phone Number is required.
                                    </span>
                                    
                                </div>
                              </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 pb-4">
                        <div class="form-group">
                            <label>City<span class="text-danger">*</span></label>
                            <input type="text" class=" form-control" formControlName="p_city" #City>
                            <div *ngIf="StudentDetails2.get('p_city').invalid && (StudentDetails2.get('p_city').dirty || StudentDetails2.get('p_city').touched)">
                                <div *ngIf="StudentDetails2.get('p_city').errors.required">
                                    <span class="text-danger">City name is required.
                                    </span>
                                    
                                </div>
                              </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 pb-4">
                        <div class="form-group">
                            <label>State<span class="text-danger">*</span></label>
                            <input type="text" class=" form-control" formControlName="p_state" #state>
                            <div *ngIf="StudentDetails2.get('p_state').invalid && (StudentDetails2.get('p_state').dirty || StudentDetails2.get('p_state').touched)">
                                <div *ngIf="StudentDetails2.get('p_state').errors.required">
                                    <span class="text-danger">State name is required.
                                    </span>
                                    
                                </div>
                              </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 pb-4">
                        <div class="form-group">
                            <label>District<span class="text-danger">*</span></label>
                            <input type="text" class=" form-control" formControlName="p_district" #distric> 
                            <div *ngIf="StudentDetails2.get('p_district').invalid && (StudentDetails2.get('p_district').dirty || StudentDetails2.get('p_district').touched)">
                                <div *ngIf="StudentDetails2.get('p_district').errors.required">
                                    <span class="text-danger">District name is required.
                                    </span>
                                    
                                </div>
                              </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 pb-4">
                        <div class="form-group">
                            <label>Pincode<span class="text-danger">*</span></label>
                            <input type="text" class=" form-control" formControlName="p_pincode" #pincode>
                            <div *ngIf="StudentDetails2.get('p_pincode').invalid && (StudentDetails2.get('p_pincode').dirty || StudentDetails2.get('p_pincode').touched)">
                                <div *ngIf="StudentDetails2.get('p_pincode').errors.required">
                                    <span class="text-danger">Pincode is required.
                                    </span>
                                    
                                </div>
                              </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 pb-4">
                        <div class="form-group">
                          <label>Gender<span class="text-danger">*</span></label><br>
                          <label for="  " class="mx-5">
                            <input type="radio" name="p_Gender" id="male" [(ngModel)]="gender" formControlName="p_Gender" value="male" > Male
                          </label>
                          <label for="female" class="mx-5">
                            <input type="radio" id="female" name="p_Gender" [(ngModel)]="gender" formControlName="p_Gender" value="female" > Female
                          </label>
                          <div *ngIf="StudentDetails2.get('p_Gender').invalid && (StudentDetails2.get('p_Gender').dirty || StudentDetails2.get('p_Gender').touched)">
                            <div *ngIf="StudentDetails2.get('p_Gender').errors.required">
                                <span class="text-danger">Gender is required.
                                </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    
                    <div class="col-12">
                        <div>
                            <p>Education Details</p>
                        </div>
                    </div>
            
                    <div class="col-lg-6 col-md-6 pb-4">
                        <div class="form-group">
                            <label>University/College:</label>
                            <input type="text" class=" form-control" formControlName="p_institution_name">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 pb-4">
                        <div class="form-group">
                            <label>Degree</label>
                            <input type="text" class=" form-control" formControlName="p_ug_degree">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 pb-4">
                        <div class="form-group">
                            <label>Stream</label>
                            <input type="text" class=" form-control" formControlName="p_ug_dept">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 pb-4">
                        <div class="form-group">
                            <label>Passout Year</label>
                            <input type="number" max="4" class=" form-control" formControlName="p_ug_yoc"
                             #YearOfPassing>
                        </div>
                    </div>
            
                    <div class="col-12">
                        <div>
                            <p>Work Experience</p>
                        </div>
                    </div>
            
                    <div class="col-lg-6 col-md-6 pb-4">
                        <div class="form-group">
                            <label>Job Title:</label>
                            <input type="text" class=" form-control" formControlName="p_course_name">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 pb-4">
                        <div class="form-group">
                            <label>Employer:</label>
                            <input type="text" class=" form-control" formControlName="p_father_name">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 pb-4">
                        <div class="form-group">
                            <label>Duration of Work (Years):	</label>
                            <input type="number" class=" form-control" formControlName="p_pg_degree" #DurationofWork1>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 pb-4">
                        <div class="form-group">
                            <label>Key Responsibilities:	</label>
                            <textarea class=" form-control" formControlName="p_others_mrksprcnt"></textarea>
                        </div>
                    </div>
            
            
                    <div class="col-12">
                        <div class="form-group">
                           <p>Upload Resume <span class="text-danger">*</span></p>
                        </div>
                    </div>
            
                    <div class="col-12 text-center">
                        <div class="form-group">
                            <input type="file" class=" form-control" formControlName="p_upload_photo" (change)="onFileSelect($event)" style="border: none;" #resume>
                            <div *ngIf="StudentDetails2.controls.p_upload_photo.errors && (StudentDetails2.controls.p_upload_photo.dirty || StudentDetails2.controls.p_upload_photo.touched)">
                                <div *ngIf="StudentDetails2.controls.p_upload_photo.errors.required">
                                 <span class="text-danger">File is required</span> 
                                </div>
                                <!-- You can add more validation messages as needed -->
                              </div>
                        </div>
                    </div>
                    
                    <!-- <div class="col-lg-6 col-md-6 pb-4">
                        <div class="form-group">
                            <label>Password</label>
                            <input type="password" class=" form-control" formControlName="">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 pb-4">
                        <div class="form-group">
                            <label>Country</label>
                            <input type="text" class=" form-control" formControlName="">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 pb-4">
                        <div class="form-group">
                            <label>Organization</label>
                            <input type="text" class=" form-control" formControlName="">
                        </div>
                    </div> -->
                    <div class=" form-check">
                        <input type="checkbox" class=" form-check-input" id="termsAgreed" formControlName="termsAgreed" #check>
                        <label class=" form-check-label" for="termsAgreed">
                            <span class="text-danger">*</span> By clicking, you are agreeing to our applicable <a >terms</a> and <a >privacy policy</a>
                        </label>
                        <div *ngIf="StudentDetails2.get('termsAgreed').invalid && (StudentDetails2.get('termsAgreed').dirty || StudentDetails2.get('termsAgreed').touched)">
                          <div *ngIf="StudentDetails2.get('termsAgreed').errors.requiredTrue">
                            You must agree to the terms.
                          </div>
                        </div>
                      </div>
                    <div class="col-lg-12 col-md-12 text-center mt-5">
                        <div class="">
                            <button class="btn" type="submit"  >Apply Now </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
