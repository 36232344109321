import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-homefive-main-banner',
  templateUrl: './homefive-main-banner.component.html',
  styleUrls: ['./homefive-main-banner.component.scss']
})
export class HomefiveMainBannerComponent implements OnInit {

  constructor( 
    private titleService: Title,  
     private metaTagService: Meta) {
    
   }
 
   ngOnInit(): void {
     this.titleService.setTitle("LOP");  
     
     this.metaTagService.addTags([  
       { name: 'keywords', content: 'Angular SEO Title, Meta Description, Meta Keyword Example' },  
       { name: 'robots', content: 'index, follow' },  
       { name: 'writer', content: 'John Smith' },  
       { charset: 'UTF-8' }  
     ]);
   }

}
