<!-- your-component.component.html -->
<div class="container">
    <div class="row">
        <div class="col-12">
            <h1 class="font "> Zeptoware Registration Details</h1>
    <div class="details-container ">
      <div class="student-details" [style.display]="pdfOpen ? 'none' : 'block'" #studentDetails>       
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <th>Si>no</th>
              <th>Student Name</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>City</th>
              <th>State</th>
              <th>District</th>
              <th>Pincode</th>
              <th>Gender</th>
              <th>University</th>
              <th>Degree</th>
              <th>Stream</th>
              <th>Passout Year</th>
              <th>Job Title</th>
              <th>Employer</th>
              <th>Experience year</th>
              <th>Responsibilities</th>
              <th>Download Pdf</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let StudentDetail of StudentDetails; let i=index">
              <td>{{ i+1 }}</td>
              <td>{{ StudentDetail.first_name }}</td>
              <td>{{ StudentDetail.emailid }}</td>
              <td>{{ StudentDetail.mobile_no }}</td>
              <td>{{ StudentDetail.city }}</td>
              <td>{{ StudentDetail.state }}</td>
              <td>{{ StudentDetail.district }}</td>
              <td>{{ StudentDetail.pincode }}</td>
              <td>{{ StudentDetail.Gender }}</td>
              <td>{{ StudentDetail.institution_name }}</td>
              <td>{{ StudentDetail.ug_degree }}</td>
              <td>{{ StudentDetail.ug_department }}</td>
              <td>{{ StudentDetail.ug_year_of_completed }}</td>
              <td>{{ StudentDetail.course_name }}</td>
              <td>{{ StudentDetail.father_name }}</td>
              <td>{{ StudentDetail.pg_degree }}</td>
              <td>{{ StudentDetail.other_mark }}</td>
              <td><button class="btn btn-danger" (click)="OpenPdf(StudentDetail)">View</button></td>
            </tr>
          </tbody>
        </table>
      </div>
        </div>
    </div>


      <div class="row">
       <div class="col-12 col-xl-6 col-lg-6">
        <div class="StudentDetailsDisplay "  [style.display]="pdfOpen ? 'block' : 'none'" >
            <div class="container">
                <div class="row ">
                    <div class="col-12">
                        <h1 class="text-center text-color font"><span><i class='bx bx-arrow-back ' [style.display]="pdfOpen ? 'inline-block' : 'none'" (click)="GoBack()"></i>Students Details</span></h1>
            <table class="table table-bordered table-hover">
               <tbody>
                <tr>
                  <td class="bold text-color1">Student Name</td>
                  <td class="bold text-color1">{{ datasFromSingleStudent.first_name }}</td>
                </tr>
                <tr>
                  <td class="bold text-color1">Email</td>
                  <td class="bold text-color1">{{ datasFromSingleStudent.emailid }}</td>
                </tr>
                <tr>
                  <td class="bold text-color1">Phone Number</td>
                  <td class="bold text-color1">{{ datasFromSingleStudent.mobile_no }}</td>
                </tr>
                <tr>
                  <td class="bold text-color1">City</td>
                  <td class="bold text-color1">{{ datasFromSingleStudent.city }}</td>
                </tr>
                <tr>
                  <td class="bold text-color1">State</td>
                  <td class="bold text-color1">{{ datasFromSingleStudent.state }}</td>
                </tr>
                <tr>
                  <td class="bold text-color1">District</td>
                  <td class="bold text-color1">{{ datasFromSingleStudent.district }}</td>
                </tr>
                <tr>
                  <td class="bold text-color1">Pincode</td>
                  <td class="bold text-color1">{{ datasFromSingleStudent.pincode }}</td>
                </tr>
                <tr>
                  <td class="bold text-color1">Gender</td>
                  <td class="bold text-color1">{{ datasFromSingleStudent.Gender }}</td>
                </tr>
                <tr>
                  <td class="bold text-color ">Institution</td>
                  <td class="bold text-color">{{ datasFromSingleStudent.institution_name }}</td>
                </tr>
                <tr>
                  <td class="bold text-color">Degree</td>
                  <td class="bold text-color">{{ datasFromSingleStudent.ug_degree }}</td>
                </tr>
                <tr>
                  <td class="bold text-color">Stream</td>
                  <td class="bold text-color">{{ datasFromSingleStudent.ug_department }}</td>
                </tr>
                <tr>
                  <td class="bold text-color">Passout Year</td>
                  <td class="bold text-color">{{ datasFromSingleStudent.ug_year_of_completed }}</td>
                </tr>
                <tr>
                  <td class="bold text-color">Job Title</td>
                  <td class="bold text-color">{{ datasFromSingleStudent.course_name }}</td>
                </tr>
                <tr>
                  <td class="bold text-color">Employer</td>
                  <td class="bold text-color">{{ datasFromSingleStudent.father_name }}</td>
                </tr>
                <tr>
                  <td class="bold text-danger">Experience year</td>
                  <td class="bold text-danger">{{ datasFromSingleStudent.pg_degree }}</td>
                </tr>
                <tr>
                  <td class="bold text-danger">Responsibilities</td>
                  <td class="bold text-danger">{{ datasFromSingleStudent.other_mark }}</td>
                </tr>
            </tbody>
              </table>
                
                    </div>
                </div>
            </div>
    
          </div>
       </div>
       <div class="col-12 col-xl-6 col-lg-6">
        <div class="pdf-container" [style.display]="pdfOpen ? 'block' : 'none'" >
        </div>
       </div>
    
         
      </div>
    
      
    </div>
  </div>
  