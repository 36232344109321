import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-home-page-five',
  templateUrl: './home-page-five.component.html',
  styleUrls: ['./home-page-five.component.scss']
})
export class HomePageFiveComponent implements OnInit {

  constructor( 
    private titleService: Title,  
     private metaTagService: Meta) {
    
   }
 
   ngOnInit(): void {
     this.titleService.setTitle("ghjk");  
     
     this.metaTagService.addTags([  
       { name: 'keywords', content: 'Angular SEO Title, Meta Description, Meta Keyword Example' },  
       { name: 'robots', content: 'index, follow' },  
       { name: 'writer', content: 'John Smith' },  
       { charset: 'UTF-8' }  
     ]);
   }

}
