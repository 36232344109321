<div class="become-instructor-partner-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="become-instructor-partner-content bg-color">
                    <h2>Looking for the Best Software IT Training Institute in Chennai?</h2>
                    <p>
                        Unlock your potential with the best software IT training institute in Chennai, 
                        offering unparalleled courses and industry expertise.</p>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="become-instructor-partner-image bg-image1">
                    <img src="assets/img/become-instructor.jpg" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="become-instructor-partner-image bg-image2">
                    <img src="assets/img/become-partner.jpg" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="become-instructor-partner-content">
                    <h2>Navigating the Path to Future Career Growth</h2>
                    <p>Unlock your creativity and shape a promising career path for a brighter future. Harness the power of creativity to fuel your career growth and achieve your dreams.</p>
                </div>
            </div>
        </div>
    </div>
</div>