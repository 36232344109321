













import { Component, HostListener, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
    selector: 'app-homefive-courses',
    templateUrl: './homefive-courses.component.html',
    styleUrls: ['./homefive-courses.component.scss']
})
export class HomefiveCoursesComponent implements OnInit {

    constructor( 
        private titleService: Title,  
         private metaTagService: Meta) {
        
       }

    ngOnInit(): void {
        this.titleService.setTitle("Best Software Training Institutes in Chennai with placement- Zeptoware ");  
         
         this.metaTagService.addTags([  
           { name: 'keywords', content: 'Angular SEO Title, Meta Description, Meta Keyword Example' },  
           { name: 'robots', content: 'index, follow' },  
           { name: 'writer', content: 'John Smith' },  
           { charset: 'UTF-8' }  
         ]);

        if( this.isLgScreen = window.innerWidth >= 992){
            this.isLgScreen=true;
        }
        else{
            this.isLgScreen=false;
        }
    }

    // for tab click event
    currentTab = 'tab1';
    switchTab(event: MouseEvent, tab: string) {
        event.preventDefault();
        this.currentTab = tab;
    }


    isLgScreen:boolean=true;

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.isLgScreen = window.innerWidth >= 992; 
    console.log(this.isLgScreen)
  }

}