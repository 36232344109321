import { Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { FormService } from '../../service/form.service';
declare var $: any;

@Component({
  selector: 'app-header-style-one',
  templateUrl: './header-style-one.component.html',
  styleUrls: ['./header-style-one.component.scss']
})
export class HeaderStyleOneComponent implements OnInit {

    isSticky: boolean = false;
    @HostListener('window:scroll', ['$event'])
    checkScroll() {
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (scrollPosition >= 50) {
            this.isSticky = true;
        } else {
            this.isSticky = false;
        }
    }

    constructor(private FB:FormBuilder,private service:FormService,private toastr: ToastrService){

    }
    showPopup(){
      $('#exampleModal22').modal('show');
    }
    ngOnInit(){

      if (this.service.shouldShowPopup()) {
        this.showPopup();
       
      }
    
      this.service.setPopupShown();
      console.log('shown'+"-->"+this.service.shouldShowPopup())
      
     
      

      this.StudentDetails1 = this.FB.group({
        p_name: ['', Validators.required],
        p_mbl_no: ['', Validators.required],
        p_email: ['', [Validators.required, Validators.email]],
        p_course: ['FullStackDevelopment', Validators.required],
        p_remarks: ['', Validators.required]
      });
    }

    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }

    classApplied2 = false;
    toggleClass2() {
        this.classApplied2 = !this.classApplied2;
    }

    classApplied3 = false;
    toggleClass3() {
        this.classApplied3 = !this.classApplied3;
    }

    StudentDetails1:FormGroup;
  
  
  GetStudentDetails(){
    const value= this.StudentDetails1.value;
    
    if (this.StudentDetails1.invalid) {
      this.toastr.error('Please fill in all required fields.', 'Error');
      return;
    }

    this.service.popupStudentDetails(value).subscribe((data)=>{
      console.log(data)
    })
    this.toastr.success('Registration SuccessFul')
    
  }
  SEO(){
    window.open('assets/pdf/SEO.pdf','_blank')
   }
   digitalMarketing(){
    window.open('assets/pdf/DigitalMarketing.pdf','_blank')
  }
  softwareTesting(){
    window.open('assets/pdf/SoftwareTest.pdf','_blank')
  }
  FullStackDevelopment(){
    window.open('assets/img/courses/Full Stack Development Course Syllabus.pdf','_blank')
  }
  mobileAppDevelopment(){
    window.open('assets/img/courses/Mobile Application Development Course Syllabus.pdf','_blank')
  }
  Database(){
    window.open('assets/pdf/MySql.pdf','_blank')
  }
   
}