import { Component, ElementRef, ViewChild } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
declare var $: any;
@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.scss']
})
export class ServiceComponent{
  
  @ViewChild('exampleModalst') exampleModal!: ElementRef;
  @ViewChild('exampleModal2') exampleModal2!: ElementRef;
  @ViewChild('exampleModal3') exampleModal3!: ElementRef;
  @ViewChild('exampleModal4') exampleModal4!: ElementRef;
  @ViewChild('exampleModal5') exampleModal5!: ElementRef;
  @ViewChild('exampleModal6') exampleModal6!: ElementRef;
  @ViewChild('exampleModal7') exampleModal7!: ElementRef;
  @ViewChild('exampleModal8') exampleModal8!: ElementRef;
  
  idName: string = 'exampleModal22';

  showPopup1(data:string) {
    // Access the modal directly using ViewChild
    if (data=='Software Training') {
      $(this.exampleModal.nativeElement).modal('show');
    }
    else if (data=='Real Time Project Experience') {
      $(this.exampleModal2.nativeElement).modal('show');
    }
    else if (data=='Software Development') {
      $(this.exampleModal3.nativeElement).modal('show');
    }
    else if (data=='Project Internship') {
      $(this.exampleModal4.nativeElement).modal('show');
    }
    else if (data=='Inplant Training') {
      $(this.exampleModal5.nativeElement).modal('show');
    }
    else if (data=='Industrial Visit') {
      $(this.exampleModal6.nativeElement).modal('show');
    }
    else if (data=='Man Power Consultancy') {
      $(this.exampleModal7.nativeElement).modal('show');
    }
    else if (data=='Placement') {
      $(this.exampleModal8.nativeElement).modal('show');
    }
  }
  constructor( 
    private titleService: Title,  
     private metaTagService: Meta) {
    
   }
 
   ngOnInit(): void {
     this.titleService.setTitle("Professional & Reliable Services - Enhancing Your Business Growth");  
     
     this.metaTagService.addTags([  
      { name: 'description', content: 'Zeptoware offers a wide range of professional services and software solutions to meet your business needs.' }, 
      { name: 'keywords', content: 'It course training institute in chennai,It course training institute near me,Best It professional training institute in chennai,Best It professional course training institute near me,It expert training institute near me,top It expert training institute in chennai,it training institute in chennai,best it course institute in chennai,top 10 it training institutes near me,top 10 it training institutes in tamil nadu,it training and placement in chennai,best it training institute,best it training institute near me,best institute for it courses in chennai,Top It training instiute in tamil nadu,Best training institute near me,full stack developer course in chennai,full stack developer course,full stack web development course,java full stack developer course institute in chennai,java full stack developer course,full stack java developer course,full stack developer course with certification in chennai,full stack developer course with certification tamil nadu,software developer training institute in chennai,software developer training institute near me,paid intenship,it courses in chennai,it coaching centre in chennai,6 months training with stipend,job training stipend,training with stipend' },  
      { name: 'robots', content: 'index, follow' },  
       { charset: 'UTF-8' }  
     ]);
   }
}
