
import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';

@Component({
  selector: 'app-site-map',
  templateUrl: './site-map.component.html',
  styleUrls: ['./site-map.component.scss']
})
export class SiteMapComponent {


  constructor(private http: HttpClient) { 
    this.generateSitemap();
  }

  ngOnInit(): void {
   
  }
  sitemapContent: string;
  generateSitemap() {
    this.sitemapContent = `<?xml version="1.0" encoding="UTF-8"?>
    <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
      <url>
        <loc>https://zeptoware.in/</loc>
        <lastmod>2024-01-01</lastmod>
        <changefreq>monthly</changefreq>
        <priority>0.8</priority>
      </url>
      <url>
        <loc>https://zeptoware.in/home</loc>
        <lastmod>2024-01-02</lastmod>
        <changefreq>monthly</changefreq>
        <priority>0.7</priority>
      </url>
      <url>
      <loc>https://zeptoware.in/AboutUs</loc>
      <lastmod>2024-01-02</lastmod>
      <changefreq>monthly</changefreq>
      <priority>0.7</priority>
    </url>
    <url>
    <loc>https://zeptoware.in/Services</loc>
    <lastmod>2024-01-02</lastmod>
    <changefreq>monthly</changefreq>
    <priority>0.7</priority>
  </url>
  <url>
    <loc>https://zeptoware.in/SoftwareTraining</loc>
    <lastmod>2024-01-02</lastmod>
    <changefreq>monthly</changefreq>
    <priority>0.7</priority>
  </url>
  <url>
    <loc>https://zeptoware.in/RealTimeExperience</loc>
    <lastmod>2024-01-02</lastmod>
    <changefreq>monthly</changefreq>
    <priority>0.7</priority>
  </url>
  <url>
    <loc>https://zeptoware.in/ProjectInternship</loc>
    <lastmod>2024-01-02</lastmod>
    <changefreq>monthly</changefreq>
    <priority>0.7</priority>
  </url>
  <url>
    <loc>https://zeptoware.in/InplantTraining</loc>
    <lastmod>2024-01-02</lastmod>
    <changefreq>monthly</changefreq>
    <priority>0.7</priority>
  </url>
  <url>
    <loc>https://zeptoware.in/IndustrialVisit</loc>
    <lastmod>2024-01-02</lastmod>
    <changefreq>monthly</changefreq>
    <priority>0.7</priority>
  </url>
  <url>
    <loc>https://zeptoware.in/SoftwareDevelopment</loc>
    <lastmod>2024-01-02</lastmod>
    <changefreq>monthly</changefreq>
    <priority>0.7</priority>
  </url>
  <url>
    <loc>https://zeptoware.in/ManPowerConsultancy</loc>
    <lastmod>2024-01-02</lastmod>
    <changefreq>monthly</changefreq>
    <priority>0.7</priority>
  </url>
  <url>
    <loc>https://zeptoware.in/Placement</loc>
    <lastmod>2024-01-02</lastmod>
    <changefreq>monthly</changefreq>
    <priority>0.7</priority>
  </url>
  <url>
    <loc>https://zeptoware.in/Courses</loc>
    <lastmod>2024-01-02</lastmod>
    <changefreq>monthly</changefreq>
    <priority>0.7</priority>
  </url>
  <url>
    <loc>https://zeptoware.in/searchEngineOptimization</loc>
    <lastmod>2024-01-02</lastmod>
    <changefreq>monthly</changefreq>
    <priority>0.7</priority>
  </url>
 <url>
    <loc>https://zeptoware.in/digitalMarketing</loc>
    <lastmod>2024-01-02</lastmod>
    <changefreq>monthly</changefreq>
    <priority>0.7</priority>
  </url>
  <url>
    <loc>https://zeptoware.in/softwareTesting</loc>
    <lastmod>2024-01-02</lastmod>
    <changefreq>monthly</changefreq>
    <priority>0.7</priority>
  </url>
  <url>
    <loc>https://zeptoware.in/Angular</loc>
    <lastmod>2024-01-02</lastmod>
    <changefreq>monthly</changefreq>
    <priority>0.7</priority>
  </url>
  <url>
    <loc>https://zeptoware.in/React</loc>
    <lastmod>2024-01-02</lastmod>
    <changefreq>monthly</changefreq>
    <priority>0.7</priority>
  </url>
  <url>
    <loc>https://zeptoware.in/FullStackDevelopment</loc>
    <lastmod>2024-01-02</lastmod>
    <changefreq>monthly</changefreq>
    <priority>0.7</priority>
  </url>
  <url>
    <loc>https://zeptoware.in/mobileAppDevelopment</loc>
    <lastmod>2024-01-02</lastmod>
    <changefreq>monthly</changefreq>
    <priority>0.7</priority>
  </url>
  <url>
    <loc>https://zeptoware.in/Java</loc>
    <lastmod>2024-01-02</lastmod>
    <changefreq>monthly</changefreq>
    <priority>0.7</priority>
  </url>
  <url>
    <loc>https://zeptoware.in/databaseDevelopment</loc>
    <lastmod>2024-01-02</lastmod>
    <changefreq>monthly</changefreq>
    <priority>0.7</priority>
  </url>
  <url>
    <loc>https://zeptoware.in/careers</loc>
    <lastmod>2024-01-02</lastmod>
    <changefreq>monthly</changefreq>
    <priority>0.7</priority>
  </url>
  <url>
    <loc>https://zeptoware.in/contact</loc>
    <lastmod>2024-01-02</lastmod>
    <changefreq>monthly</changefreq>
    <priority>0.7</priority>
  </url>
  <url>
    <loc>https://zeptoware.in/Internship</loc>
    <lastmod>2024-01-02</lastmod>
    <changefreq>monthly</changefreq>
    <priority>0.7</priority>
  </url>
  <url>
    <loc>https://zeptoware.in/Internship</loc>
    <lastmod>2024-01-02</lastmod>
    <changefreq>monthly</changefreq>
    <priority>0.7</priority>
  </url>                

    </urlset>`;
  }
}
