import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-full-stack-development',
  templateUrl: './full-stack-development.component.html',
  styleUrls: ['./full-stack-development.component.scss']
})
export class FullStackDevelopmentComponent {

  constructor( 
    private titleService: Title,  
     private metaTagService: Meta) {
    
   }

   currentDate: Date = new Date();

 
   ngOnInit(): void {
     this.titleService.setTitle("Fullstack Course Training Institute in Chennai | India");  
     
     this.metaTagService.addTags([  
      { name: ' descripiton', content: 'Take your web development to the next level with Zeptoware proficiency in full stack development, providing cutting-edge and effective solutions for your digital requirements' },
      { name: 'keywords', content: 'It course training institute in chennai,It course training institute near me,Best It professional training institute in chennai,Best It professional course training institute near me,It expert training institute near me,top It expert training institute in chennai,it training institute in chennai,best it course institute in chennai,top 10 it training institutes near me,top 10 it training institutes in tamil nadu,it training and placement in chennai,best it training institute,best it training institute near me,best institute for it courses in chennai,Top It training instiute in tamil nadu,Best training institute near me,full stack developer course in chennai,full stack developer course,full stack web development course,java full stack developer course institute in chennai,java full stack developer course,full stack java developer course,full stack developer course with certification in chennai,full stack developer course with certification tamil nadu,software developer training institute in chennai,software developer training institute near me,paid intenship,it courses in chennai,it coaching centre in chennai,6 months training with stipend,job training stipend,training with stipend' },  
      { name: 'robots', content: 'index, follow' }, 
      { charset: 'UTF-8' }  
     ]);
   }
   
  FullStackDevelopment(){
    window.open('assets/img/courses/Full Stack Development Course Syllabus.pdf','_blank')
  }

}
