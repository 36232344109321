<app-header-style-one></app-header-style-one>

<app-homefive-main-banner></app-homefive-main-banner>
<!-- <div>
    <img src="../../../../assets/img/courses/c1.gif" alt="">
</div> -->
<div class="funfacts-area pt-100">
    <div class="container">
        <div class="funfacts-inner">
            <app-funfacts></app-funfacts>
         
            <div id="particles-js-circle-bubble"></div>
        </div>
    </div>
</div>

<app-homefive-courses></app-homefive-courses>

<div class="courses-categories-area pb-70">
    <div class="container">
        <div class="section-title text-start">
            <span class="sub-title">Courses Categories</span>
            <h2>Browse Trending Categories</h2>
            <a routerLink="/Courses" class="default-btn"><i class='bx bx-show-alt icon-arrow before'></i><span class="label">View All</span><i class="bx bx-show-alt icon-arrow after"></i></a>
        </div>
        <app-categories-style-two></app-categories-style-two>
    </div>
</div>

<!-- <div class="partner-area pb-100">
    <div class="container">
        <div class="section-title">
            <h2>Our Company & Partners</h2>
        </div>
        <app-partner-style-two></app-partner-style-two>
    </div>
</div> -->
<div class="become-instructor-partner-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="become-instructor-partner-content bg-color">
                    <h2>Looking for the Best Software IT Training Institute in Chennai?</h2>
                    <p>
                        Unlock your potential with the best software IT training institute in Chennai, 
                        offering unparalleled courses and industry expertise.</p>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="become-instructor-partner-image bg-image1">
                    <img src="assets/img/become-instructor.jpg" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="become-instructor-partner-image bg-image2">
                    <img src="assets/img/become-partner.jpg" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="become-instructor-partner-content">
                    <h2>Navigating the Path to Future Career Growth</h2>
                    <p>Unlock your creativity and shape a promising career path for a brighter future. Harness the power of creativity to fuel your career growth and achieve your dreams.</p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="mb-3"></div>

<!-- <div class="testimonials-area pt-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Testimonials</span>
            <h2>What Students Says</h2>
        </div>
        <app-feedback-style-two></app-feedback-style-two>
    </div>
</div> -->

<!-- <div class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-start">
            <span class="sub-title">Explore News</span>
            <h2>Our Latest Insights</h2>
            <a routerLink="/blog-style-1" class="default-btn"><i class='bx bx-book-reader icon-arrow before'></i><span class="label">Read All</span><i class="bx bx-book-reader icon-arrow after"></i></a>
        </div>
        <app-blog></app-blog>
    </div>
</div> -->