import { Component, ElementRef, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Meta, Title } from '@angular/platform-browser';
import { FormService } from '../../service/form.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent {
@ViewChild('name') name?:ElementRef;
@ViewChild('email') email:ElementRef;
@ViewChild('phoneNumber') phoneNumber:ElementRef;
@ViewChild('City') City:ElementRef;
@ViewChild('state') state:ElementRef;
@ViewChild('distric') distric:ElementRef;
@ViewChild('pincode') pincode:ElementRef;
@ViewChild('check') check:ElementRef;
@ViewChild('resume') resume:ElementRef;
@ViewChild('YearOfPassing') YearOfPassing:ElementRef;
@ViewChild('DurationofWork1') DurationofWork1:ElementRef;
DurationofWork
  url: any;
  gender:string;

  constructor(private FB:FormBuilder,private service:FormService,private toastr: ToastrService, private titleService: Title,  
    private metaTagService: Meta){

  }
 

  ngOnInit(){
    

    this.titleService.setTitle("Example of SEO Meta Tag using Angular");  
     
     this.metaTagService.addTags([  
       { name: 'keywords', content: 'Angular SEO Title, Meta Description, Meta Keyword Example' },  
       { name: 'robots', content: 'index, follow' },  
       { name: 'writer', content: 'John Smith' },  
       { charset: 'UTF-8' }  
     ]);

    this.StudentDetails2 = this.FB.group({
'avatar':'',
      p_first_name: [null, Validators.required],
      p_emailid: [null, [Validators.required, Validators.email]],
      p_mobnum: [null, [Validators.required, Validators.maxLength(10), Validators.minLength(10)]], // Fixed validators array
      p_city: [null, Validators.required],
      p_state: [null, Validators.required],
      p_district: [null, Validators.required],
      p_pincode: [null, Validators.required],
      p_Gender: [null, Validators.required],
      p_institution_name: [null],
      p_ug_degree: [null],
      p_ug_dept: [null],
      p_ug_yoc: [null],
      p_course_name: [null],
      p_father_name: [null],
      p_pg_degree: [null],
      p_others_mrksprcnt: [null],
      p_upload_photo: ['' ],
      termsAgreed: [false, Validators.requiredTrue]
    });
    
  }
  

  





  validateDocument(control: AbstractControl): Promise<any> | Observable<any> {
    const file = control.value;
  
    // Check if the file is not null or undefined
    if (!file || !file.name) {
      return Promise.resolve(null);
    }
  
    const allowedTypes = ['pdf', 'doc', 'docx']; // Allowed document file types
  
    return new Promise((resolve) => {
      const fileType = file.name.split('.').pop().toLowerCase();
      if (allowedTypes.indexOf(fileType) === -1) {
        resolve({ invalidFileType: true });
      } else {
        resolve(null);
      }
    });
  }
  isGmail(email: string): boolean {
    if( email.endsWith('@gmail.com')){
      return false;
    }
    else{
      return true;
    }
  }
  StudentDetails2: FormGroup;
 
  GetStudentDetails(){
    

  
this.StudentDetails2.get('p_Gender').setValue(this.gender);
this.StudentDetails2.get('p_ug_yoc').setValue(this.YearOfPassing.nativeElement.value);
    console.log(this.StudentDetails2.value)
   
      if(this.name.nativeElement.value == ''){
        this.name.nativeElement.focus()
        this.toastr.warning('Please fill in the Name');

      }
      else if(this.isGmail(this.email.nativeElement.value) ){
        this.email.nativeElement.focus()
        this.toastr.warning('Please fill in the email correct');

      }
      else if(this.phoneNumber.nativeElement.value.length != 10 ){
        console.log(this.isGmail(this.email.nativeElement.value))
        this.phoneNumber.nativeElement.focus()
        this.toastr.warning('Please fill in the PhoneNumber in 10 digit');

      }
      else if(this.City.nativeElement.value == ''){
        this.City.nativeElement.focus()
        this.toastr.warning('Please fill in the City Name');

      }
      else if(this.state.nativeElement.value == ''){
        this.state.nativeElement.focus()
        this.toastr.warning('Please fill in the StateName');

      }
      else if(this.distric.nativeElement.value == ''){
        this.distric.nativeElement.focus()
        this.toastr.warning('Please fill in the Distric Name');

      }
      else if(this.pincode.nativeElement.value == ''){
        this.pincode.nativeElement.focus()
        this.toastr.warning('Please fill in the pincode');

      }
     
      else if(this.resume.nativeElement.value == ''){
        console.log(this.DurationofWork1.nativeElement.value)
        this.resume.nativeElement.focus()
        this.toastr.warning('Please Upload Your Resume');

      }
      else if (!this.StudentDetails2.get('termsAgreed').value) {
        this.check.nativeElement.focus();
        this.toastr.warning('Accept Our Privacy Policy');
      }
      else{
        
        this.StudentDetails2.get('p_upload_photo').setValue(this.url)
            const formData1 = new FormData();
            formData1.append('avatar', this.StudentDetails2.get('avatar').value);
                this.service.uploadFile(formData1).subscribe(formdata=> {
                 
                this.StudentDetails2.value.p_upload_photo=formdata.url;
                this.service.SentStudentFormDetais(this.StudentDetails2.value).subscribe((data)=>{
                  console.log(data)
                 })
                })
        
        console.log(this.StudentDetails2.value)
          
            this.toastr.success('Registration SuccessFul')
          

      }
     
   

  
    
  }

  onFileSelect(event) {
    console.log('count '+event.target.files.length)
    // if (event.target.files.length > 0) { 
      for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files[i];
        this.StudentDetails2.get('avatar').setValue(file);
        // this.onSubmit();
      }             
    // }
  }



  
  
  
  
}