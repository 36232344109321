import { Component, OnInit } from '@angular/core';
import { FormService } from '../../service/form.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-student-details',
  templateUrl: './student-details.component.html',
  styleUrls: ['./student-details.component.scss']
})
export class StudentDetailsComponent implements OnInit {
  StudentDetails: any[] = [];
   datasFromSingleStudent: {
    first_name: string,
    emailid: string,
    mobile_no: string,
    city: string,
    state: string,
    district: string,
    pincode: string,
    Gender: string,
    institution_name: string,
    ug_degree: string,
    ug_department: string,
    ug_year_of_completed: string,
    course_name: string,
    father_name: string,
    pg_degree: string,
    other_mark  : string
} = {
    first_name: '',
    emailid: '',
    mobile_no: '',
    city: '',
    state: '',
    district: '',
    pincode: '',
    Gender: '',
    institution_name: '',
    ug_degree: '',
    ug_department: '',
    ug_year_of_completed: '',
    course_name: '',
    father_name: '',
    pg_degree: '',
    other_mark: ''
};
  constructor(private service: FormService, private router:Router) { }

  ngOnInit(): void {
    const data=sessionStorage.getItem('Admin')
    if (!data) {
      this.router.navigate(['/AdminLogin']);
    } 
    this.service.StudentDetailsGet(-1).subscribe(
      (response) => {
       

        if (response.status === 'success' && response.code === '200') {
          console.log(response.data);
          console.log(`response123.data`);

          this.StudentDetails = response.data;
         
        } else {
          console.error('Error occurred while fetching data');
        }
      },
      (error) => {
        console.error('Error occurred while fetching data:', error);
      }
    );

    this.service.StudentDetailsGet(6).subscribe(
      (response) => {
       

        if (response.status === 'success' && response.code === '200') {
          console.log(response.data);
          console.log(`response123.data`);

          this.datasFromSingleStudent = response.data;
         
        } else {
          console.error('Error occurred while fetching data');
        }
      },
      (error) => {
        console.error('Error occurred while fetching data:', error);
      }
    );
  }
  ngOnDestroy(): void {
    sessionStorage.clear();
  }
  pdfOpen:boolean=false;
 
  OpenPdf(data: any) {
    console.log(data)
    this.datasFromSingleStudent=data
    const url = data.upload_photo;
    const pdfContainer = document.querySelector('.pdf-container') as HTMLElement;
    pdfContainer.innerHTML = `<object data="${url}" type="application/pdf" width="100%" height="700vh" ></object>`;

    const name = data.first_name;
    const StudentDetailsDisplay = document.querySelector('.StudentDetailsDisplay') as HTMLElement;
    // StudentDetailsDisplay.textContent = name;
 // Set background color to red
    StudentDetailsDisplay.style.width = '100%'; // Set width to 50%

    this.pdfOpen = true;
}


GoBack(){
  this.pdfOpen=false;
  this.datasFromSingleStudent={
    first_name: '',
    emailid: '',
    mobile_no: '',
    city: '',
    state: '',
    district: '',
    pincode: '',
    Gender: '',
    institution_name: '',
    ug_degree: '',
    ug_department: '',
    ug_year_of_completed: '',
    course_name: '',
    father_name: '',
    pg_degree: '',
    other_mark: ''
};
}
  
    
}
