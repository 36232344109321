import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-react',
  templateUrl: './react.component.html',
  styleUrls: ['./react.component.scss']
})
export class ReactComponent {
  currentDate: Date = new Date();
  constructor( 
    private titleService: Title,  
     private metaTagService: Meta) {
    
   }
   React(){

   }
 
   ngOnInit(): void {
     this.titleService.setTitle("React Software Training Institute - Enhance Your Skills Now");  
     
     this.metaTagService.addTags([  
      { name: 'description', content: 'Looking for the top React course in Chennai? Look no further! Join our software training institute for expert guidance and hands-on learning.' }, 
      { name: 'keywords', content: 'It course training institute in chennai,It course training institute near me,Best It professional training institute in chennai,Best It professional course training institute near me,It expert training institute near me,top It expert training institute in chennai,it training institute in chennai,best it course institute in chennai,top 10 it training institutes near me,top 10 it training institutes in tamil nadu,it training and placement in chennai,best it training institute,best it training institute near me,best institute for it courses in chennai,Top It training instiute in tamil nadu,Best training institute near me,full stack developer course in chennai,full stack developer course,full stack web development course,java full stack developer course institute in chennai,java full stack developer course,full stack java developer course,full stack developer course with certification in chennai,full stack developer course with certification tamil nadu,software developer training institute in chennai,software developer training institute near me,paid intenship,it courses in chennai,it coaching centre in chennai,6 months training with stipend,job training stipend,training with stipend' },  
      { name: 'robots', content: 'index, follow' },
       { charset: 'UTF-8' }  
     ]);
   }
}
