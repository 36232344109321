import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // Define the correct username and password
  private readonly hardcodedStudentUsername = 'dharun';
  private readonly hardcodedStudentPassword = 'dharun';

  login(username: string, password: string): string | null {
    // Check if the provided username and password match the hardcoded credentials
    if (username === this.hardcodedStudentUsername && password === this.hardcodedStudentPassword) {
      // Store authentication token or other information in local storage
      localStorage.setItem('token', 'authenticated');
      return 'student'; // Authentication successful for student
    } 
    return null; // Authentication failed
  }

  isLoggedIn(): boolean {
    // Check if the user is logged in by verifying the presence of the authentication token
    return !!localStorage.getItem('token');
  }

  logout(): void {
    // Clear authentication token from local storage upon logout
    localStorage.removeItem('token');
  }
}
