 


<div class="offer-area pt-100">
    <div class="container">
        
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-offer-box pb-5  ">
                    <div class="icon">
                        <i class='bx bxs-graduation'></i>
                    </div>
                    <h3>Expert Training</h3>
                    <p class="pb-4">Expert trainers possess in-depth knowledge and expertise in their respective fields.</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-offer-box">
                    <div class="icon">
                        <i class='bx bx-globe'></i>
                    </div>
                    <h3>Practical Training</h3>
                    <p class="pb-4">Hands-on learning enhances knowledge retention as individuals are actively engaged in the learning process.</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 ">
                <div class="single-offer-box">
                    <div class="icon">
                        <i class='bx bx-collapse-horizontal'></i>
                    </div>
                    <h3>Live Projects</h3>
                    <p>Participants work collaboratively on live projects, simulating a team-based work environment commonly found in professional settings.</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-offer-box">
                    <div class="icon">
                        <i class='bx bxs-flag-alt'></i>
                    </div>
                    <h3>Placement Support</h3>
                    <p>Providing access to industry connections and networking opportunities that can enhance a candidate's visibility within their chosen field.</p>
                </div>
            </div>
            
        </div>
        <div class="row">
            <div class="courses-categories-area bg-image mt-3">
                <div class="container ">
                    <div class="row text-center">
                        <div class="col-lg-3 col-md-12  order-lg-10 p-3">
                            <div class="single-courses-box mb-30 skew">
                                <div class="courses-content arrow-container bgpink ">
                                    <h2 class="text-light">Joining Process</h2>
                                   <p class="text-light">Counselling + Entrance Test + Personal Interview + Documentation.</p>
                                </div>
                               
    
                               
                            </div>
                            <div *ngIf="!isLgScreen" class="arrow-down w-100 d-flex justify-content-center"></div>

                        </div>
                        <div *ngIf="isLgScreen" class="col-lg-1 col-md-12 pt-100 order-lg-9 order-sm-9">
                            <i  class="arrow-right"></i>
                        </div>
                        
                        <div class="col-lg-3 col-md-12  order-lg-8  ">
                            <div class="single-courses-box mb-30 skew">
                                <div class="courses-content arrow-container bgpink mt-5 p-3">
                                    <h2 class="text-light ">Training</h2>
                                   <p  class="text-light">5+ Hours Training & Activities( Monday to Friday (4 to 5 Months)).</p>
                                </div>
                            </div>
                            <div *ngIf="!isLgScreen" class="arrow-down w-100 d-flex justify-content-center"></div>

                        </div>
                        <div *ngIf="isLgScreen" class="col-lg-1 col-md-12 pt-100 order-lg-7 ">
                            <i  class="arrow-right"></i>
                        </div>
                        <div class="col-lg-3 col-md-12 order-lg-6 mt-4">
                            <div class="single-courses-box mb-30 skew ">
                                <div class="courses-content arrow-container bgpink p-2">
                                    <h2 class="text-light">Assessment Test
                                    </h2>
                                   <p class="text-light">Get ready to work on live projects of real client.

                                   </p>
                                </div>
                               
    
                               
                            </div>
                            <div class="arrow-down w-100 d-flex justify-content-center"></div>
                        </div>
                       
                        <div class="col-lg-3 col-md-12  order-lg-5 ">
                            <div class="single-courses-box mb-30 skew">
                                <div class="courses-content arrow-container bgpink p-2 ">
                                    <h2 class="text-light ">Full-time Work
                                    </h2>
                                   <p class="text-light p-2">After training work full-time on live project for next 6 to 7 Months (9 hours per day)</p>
                                </div>
                               
    
                               
                            </div>
                            <div *ngIf="!isLgScreen" class="arrow-down w-100 d-flex justify-content-center"></div>

                        </div>
                        <div *ngIf="isLgScreen" class="col-lg-1 col-md-12  order-lg-4 d-flex align-items-center">
                            <i  class="arrow-left"></i>
                        </div>

                        <div class="col-lg-3 col-md-12  order-lg-3 ">
                            <div class="single-courses-box mb-30 skew">
                                <div class="courses-content arrow-container bgpink p-3">
                                    <h2 class="text-light">Earn Stipend
                                    </h2>
                                   <p class="text-light">After 6 month get stipend upto 5k per month(applicable for upfront payment track).</p>
                                </div>
                               
    
                               
                            </div>
                            <div *ngIf="!isLgScreen" class="arrow-down w-100 d-flex justify-content-center"></div>

                        </div>
                        <div *ngIf="isLgScreen" class="col-lg-1 col-md-12 order-lg-2  d-flex align-items-center">
                            <i  class="arrow-left"></i>
                        </div>

                        <div class="col-lg-3 col-md-12  order-lg-1 ">
                            <div class="single-courses-box mb-30 skew">
                                <div class="courses-content arrow-container bgpink">
                                    <h2 class="text-light">1+ Years Experience
                                    </h2>
                                   <p class="text-light">Enter as beginner exit as experienced. Get ready for your next job with 5LPA.</p>
                                </div>
                               
    
                               
                            </div>
                            
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        <div class="row textstart mt-3">
            <div >
                <h2 class="h2color">ZEPTOWARE PLACEMENT & IT TRAINING IN CHENNAI</h2>
                <p >Training in Chennai Zeptoware Academy is the best future for IT and placement training, offering a large number of exploring software courses in Chennai</p>
                <p >Zeptoware seems to be an IT training and placement firm; it probably provides courses and programs in a range of IT-related subjects and helps people get employment or placements in the sector. Zeptoware's placement services probably include career counseling, CV development, interview practice, and putting their qualified individuals in touch with IT industry businesses or job openings.</p>
               
               
               
        
                <div class="row">
                <div class="col-lg-4 col-md-12 about-text">

                   <ul class="features-list text-start">
                    <li ><i class="bx bx-check"></i>IT Experts as Trainers</li>

                   </ul>

                    
                </div>

                <div class="col-lg-4 col-md-12 about-text">
                    <ul class="features-list text-start">
                        <li ><i class="bx bx-check"></i>Affordable course Fees
                        </li>
                    </ul>
                    
                </div>
                <div class="col-lg-4 col-md-12 about-text">
                    <ul class="features-list text-start">
                        <li ><i class="bx bx-check"></i>Browse for All courses
                        </li>
                    </ul>
                    
                </div>
                <div class="col-lg-4 col-md-12 about-text">
                    <ul class="features-list text-start">
                        <li ><i class="bx bx-check"></i>Interview Preparation
                        </li>
                    </ul>
                    
                </div>
                <div class="col-lg-4 col-md-12 about-text">
                    <ul class="features-list text-start">
                        <li ><i class="bx bx-check"></i>Placement Assistance
                        </li>
                    </ul>
                    
                </div>
                <div class="col-lg-4 col-md-12 about-text">
                    <ul class="features-list text-start">
                        <li ><i class="bx bx-check"></i>Real time Projects
                        </li>
                    </ul>
                    
                </div>
               
                
               </div>
               
                
               
            </div>
            <div class="row mt-3">
                <h2 class="h2color">Which is the Best Career to get into IT Training in Chennai ?</h2>
                <p>We provide real-time live projects and hands-on practical sessions along with Full stack training, Software testingtraining, Data Base training, Android training, 0S training, Embedded Systems training, AngularJ$ training, Web Designing training, Web Development training, SEO trainingand many other professional hot training courses. The syllabus of all of these courses is designed by highly qualifiedprofessionals, to make the programs the best job oriented training programs</p>
                <p>These organizations frequently cooperate with industry partners, acting as a link between enterprises looking for talent and highly trained IT people, so promoting the development of the workforce in the technology sector.</p>
            </div>
            <div class="row mt-3">
                <h2 class="h2color">Training in Chennai for Fresher’s and Beginners</h2>
                <p>Training in Chennai for Fresher and Beginners Deciding which training course to take in order to get an opportunity can be a daunting task. With so many options available, it can be overwhelming to determine which course will provide the most value and ultimately lead to the desired opportunity. However, the key to making this decision lies in understanding your goals, strengths, and the current market trends. Is it a promotion in your current job, a career change, or a new job altogether? Once you have a clear goal in mind, it is crucial to assess your strengths and weaknesses. This will help you determine which skills you need to improve upon in order to make yourself a more attractive candidate for the opportunity. Next, research the current market trends and see which skills are in high demand</p>
                
            </div>
            <div class="row mt-3">
                <h2 class="h2color">Training in Chennai for Final year Students</h2>
                <p>Chennai is a bustling hub of education and opportunities, attracting students from all over the country. As final year students prepare to enter the professional world, it is crucial for them to equip themselves with the necessary skills and knowledge to excel in their chosen fields. This is where training in Chennai for final year students comes into play. Another advantage of training in Chennai is the availability of state-of-the-art infrastructure and resources. The training institutes are equipped with modern technology and tools, providing students with a conducive learning environment.</p>
                
            </div>
        </div>
        
    </div>
</div>
<!-- Discover Courses -->
<div class="courses-area pt-100 pb-70">
    <div class="container">
       
        <div class="section-title text-start ">
            <span class="sub-title">Discover Courses</span>
            <h2>Our Popular Courses</h2>
            <a routerLink="/Courses" class="default-btn"><i class='bx bx-show-alt icon-arrow before'></i><span class="label">All Courses</span><i class="bx bx-show-alt icon-arrow after"></i></a>
        </div>
        <!-- <ul class="nav-tabset">
            <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab1'}">
                <span class="active" (click)="switchTab($event, 'tab1')">All (06)</span>
            </li>
            <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab2'}">
                <span (click)="switchTab($event, 'tab2')">Business (02)</span>
            </li>
            <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab3'}">
                <span (click)="switchTab($event, 'tab3')">Design (05)</span>
            </li>
            <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab4'}">
                <span (click)="switchTab($event, 'tab4')">Development (04)</span>
            </li>
            <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab5'}">
                <span (click)="switchTab($event, 'tab5')">Language (02)</span>
            </li>
            <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab6'}">
                <span (click)="switchTab($event, 'tab6')">Management (03)</span>
            </li>
            <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab7'}">
                <span (click)="switchTab($event, 'tab7')">Photography (04)</span>
            </li>
        </ul> -->
        <div class="tabs-container">
            <div class="pane" id="tab1" *ngIf="currentTab === 'tab1'">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image hero-banner-image">
                                <a routerLink="/FullStackDevelopment" class="d-block"><img src="assets/img/courses/full-stack-development.png" alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Development</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <!-- <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/user1.jpg" class="rounded-circle mr-2" alt="image">
                                    <span>Steven Smith</span>
                                </div> -->
                                <h3><a routerLink="/FullStackDevelopment" class="d-inline-block">Full Stack Development</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price">
                                        Free
                                    </li>
                                </ul>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image hero-banner-image">
                                <a routerLink="/FullStackDevelopment" class="d-block"><img src="assets/img/courses/db.png" alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Development</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <!-- <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/user2.jpg" class="rounded-circle mr-2" alt="image">
                                    <span>Sarah Taylor</span>
                                </div> -->
                                <h3><a routerLink="/databaseDevelopment" class="d-inline-block">Database Development</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star-half'></i>
                                    </div>
                                    <div class="rating-total">
                                        4.5 (2 rating)
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 15 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 10 lessons
                                    </li>
                                    <li class="courses-price">
                                        $250
                                    </li>
                                </ul>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image hero-banner-image">
                                <a routerLink="/FullStackDevelopment" class="d-block"><img src="assets/img2/digimar.png" alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Marketing</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <!-- <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/user3.jpg" class="rounded-circle mr-2" alt="image">
                                    <span>James Anderson</span>
                                </div> -->
                                <h3><a routerLink="/digitalMarketing" class="d-inline-block">Digital Marketing</a></h3> 
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bx-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        4.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 5 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 5 lessons
                                    </li>
                                    <li class="courses-price">
                                        $150
                                    </li>
                                </ul>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image hero-banner-image">
                                <!-- assets/img/courses/courses4.jpg -->
                                <a routerLink="/FullStackDevelopment" class="d-block"><img src="assets/img2/seomin.png" alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">SEO</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <!-- <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/user4.jpg" class="rounded-circle mr-2" alt="image">
                                    <span>Liam King</span>
                                </div> -->
                                <h3><a routerLink="/searchEngineOptimization" class="d-inline-block">Search Engine Optimization</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price">
                                        <span>$200</span>
                                        $195
                                    </li>
                                </ul>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">  
                            <div class="courses-image hero-banner-image">
                                <!-- assets/img/courses/courses5.jpg -->
                                <a routerLink="/FullStackDevelopment" class="d-block"><img src="assets/img2/mobilaappmin.png" alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Development</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <!-- <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/user5.jpg" class="rounded-circle mr-2" alt="image">
                                    <span>Lina D'Souza</span>
                                </div> -->
                                <h3><a routerLink="/mobileAppDevelopment" class="d-inline-block">Mobile App Development</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price">
                                        $178
                                    </li>
                                </ul>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image hero-banner-image">
                                <!-- assets/img/courses/courses6.jpg -->
                                <a routerLink="/FullStackDevelopment" class="d-block"><img src="assets/img2/softtestmin.png" alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Testing</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <!-- <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/user6.jpg" class="rounded-circle mr-2" alt="image">
                                    <span>David Warner</span>
                                </div> -->
                                <h3><a routerLink="/softwareTesting" class="d-inline-block">Software Testing</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price">
                                        $500
                                    </li>
                                </ul>
                            </div> -->
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image hero-banner-image">
                                <!-- assets/img/courses/courses6.jpg -->
                                <a routerLink="/Angular" class="d-block"><img src="assets/img2/angular 23.png" alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Angular</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <!-- <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/user6.jpg" class="rounded-circle mr-2" alt="image">
                                    <span>David Warner</span>
                                </div> -->
                                <h3><a routerLink="/softwareTesting" class="d-inline-block">Angular </a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price">
                                        $500
                                    </li>
                                </ul>
                            </div> -->
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image hero-banner-image">
                                <!-- assets/img/courses/courses6.jpg -->
                                <a routerLink="/React" class="d-block"><img src="assets/img2/react js 34.png" alt="image" style="height: 40vh; width: 100%;"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">React Js</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <!-- <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/user6.jpg" class="rounded-circle mr-2" alt="image">
                                    <span>David Warner</span>
                                </div> -->
                                <h3><a routerLink="/softwareTesting" class="d-inline-block">React Js</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price">
                                        $500
                                    </li>
                                </ul>
                            </div> -->
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image hero-banner-image">
                                <!-- assets/img/courses/courses6.jpg -->
                                <a routerLink="/Java" class="d-block"><img src="assets/img2/java programming.png" alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Java</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <!-- <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/user6.jpg" class="rounded-circle mr-2" alt="image">
                                    <span>David Warner</span>
                                </div> -->
                                <h3><a routerLink="/softwareTesting" class="d-inline-block">Java Programming</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price">
                                        $500
                                    </li>
                                </ul>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="pane" id="tab2" *ngIf="currentTab === 'tab2'">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image hero-banner-image">
                                <a routerLink="/FullStackDevelopment" class="d-block"><img src="assets/img/courses/courses1.jpg" alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Business</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <!-- <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/user1.jpg" class="rounded-circle mr-2" alt="image">
                                    <span>Steven Smith</span>
                                </div> -->
                                <h3><a routerLink="/FullStackDevelopment" class="d-inline-block">Ednuv Professional IT Expert Certificate Course</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price">
                                        Free
                                    </li>
                                </ul>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a routerLink="/FullStackDevelopment" class="d-block"><img src="assets/img/courses/courses3.jpg" alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Development</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <!-- <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/user3.jpg" class="rounded-circle mr-2" alt="image">
                                    <span>James Anderson</span>
                                </div> -->
                                <h3><a routerLink="/FullStackDevelopment" class="d-inline-block">Photography Crash Course for Photographer</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bx-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        4.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 5 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 5 lessons
                                    </li>
                                    <li class="courses-price">
                                        $150
                                    </li>
                                </ul>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="pane" id="tab3" *ngIf="currentTab === 'tab3'">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a routerLink="/FullStackDevelopment" class="d-block"><img src="assets/img/courses/courses1.jpg" alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Business</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <!-- <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/user1.jpg" class="rounded-circle mr-2" alt="image">
                                    <span>Steven Smith</span>
                                </div> -->
                                <h3><a routerLink="/FullStackDevelopment" class="d-inline-block">Ednuv Professional IT Expert Certificate Course</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price">
                                        Free
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a routerLink="/FullStackDevelopment" class="d-block"><img src="assets/img/courses/courses2.jpg" alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Design</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <!-- <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/user2.jpg" class="rounded-circle mr-2" alt="image">
                                    <span>Sarah Taylor</span>
                                </div> -->
                                <h3><a routerLink="/FullStackDevelopment" class="d-inline-block">Certified Graphic Design with Free Project Course</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star-half'></i>
                                    </div>
                                    <div class="rating-total">
                                        4.5 (2 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 15 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 10 lessons
                                    </li>
                                    <li class="courses-price">
                                        $250
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a routerLink="/FullStackDevelopment" class="d-block"><img src="assets/img/courses/courses4.jpg" alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Language</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/user4.jpg" class="rounded-circle mr-2" alt="image">
                                    <span>Liam King</span>
                                </div>
                                <h3><a routerLink="/FullStackDevelopment" class="d-inline-block">Web Design for Developing Technology with Joy</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price">
                                        <span>$200</span>
                                        $195
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a routerLink="/FullStackDevelopment" class="d-block"><img src="assets/img/courses/courses5.jpg" alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Management</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/user5.jpg" class="rounded-circle mr-2" alt="image">
                                    <span>Lina D'Souza</span>
                                </div>
                                <h3><a routerLink="/FullStackDevelopment" class="d-inline-block">Information About UI/UX Design Degree</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price">
                                        $178
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a routerLink="/FullStackDevelopment" class="d-block"><img src="assets/img/courses/courses6.jpg" alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Photography</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/user6.jpg" class="rounded-circle mr-2" alt="image">
                                    <span>David Warner</span>
                                </div>
                                <h3><a routerLink="/FullStackDevelopment" class="d-inline-block">Photography Photo modify and Beautiful</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price">
                                        $500
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pane" id="tab4" *ngIf="currentTab === 'tab4'">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a routerLink="/FullStackDevelopment" class="d-block"><img src="assets/img/courses/courses2.jpg" alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Design</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/user2.jpg" class="rounded-circle mr-2" alt="image">
                                    <span>Sarah Taylor</span>
                                </div>
                                <h3><a routerLink="/FullStackDevelopment" class="d-inline-block">Certified Graphic Design with Free Project Course</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star-half'></i>
                                    </div>
                                    <div class="rating-total">
                                        4.5 (2 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 15 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 10 lessons
                                    </li>
                                    <li class="courses-price">
                                        $250
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a routerLink="/FullStackDevelopment" class="d-block"><img src="assets/img/courses/courses3.jpg" alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Development</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/user3.jpg" class="rounded-circle mr-2" alt="image">
                                    <span>James Anderson</span>
                                </div>
                                <h3><a routerLink="/FullStackDevelopment" class="d-inline-block">Photography Crash Course for Photographer</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bx-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        4.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 5 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 5 lessons
                                    </li>
                                    <li class="courses-price">
                                        $150
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a routerLink="/FullStackDevelopment" class="d-block"><img src="assets/img/courses/courses4.jpg" alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Language</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/user4.jpg" class="rounded-circle mr-2" alt="image">
                                    <span>Liam King</span>
                                </div>
                                <h3><a routerLink="/FullStackDevelopment" class="d-inline-block">Web Design for Developing Technology with Joy</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price">
                                        <span>$200</span>
                                        $195
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a routerLink="/FullStackDevelopment" class="d-block"><img src="assets/img/courses/courses6.jpg" alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Photography</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/user6.jpg" class="rounded-circle mr-2" alt="image">
                                    <span>David Warner</span>
                                </div>
                                <h3><a routerLink="/FullStackDevelopment" class="d-inline-block">Photography Photo modify and Beautiful</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price">
                                        $500
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pane" id="tab5" *ngIf="currentTab === 'tab5'">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a routerLink="/FullStackDevelopment" class="d-block"><img src="assets/img/courses/courses1.jpg" alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Business</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/user1.jpg" class="rounded-circle mr-2" alt="image">
                                    <span>Steven Smith</span>
                                </div>
                                <h3><a routerLink="/FullStackDevelopment" class="d-inline-block">Ednuv Professional IT Expert Certificate Course</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price">
                                        Free
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a routerLink="/FullStackDevelopment" class="d-block"><img src="assets/img/courses/courses4.jpg" alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Language</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/user4.jpg" class="rounded-circle mr-2" alt="image">
                                    <span>Liam King</span>
                                </div>
                                <h3><a routerLink="/FullStackDevelopment" class="d-inline-block">Web Design for Developing Technology with Joy</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price">
                                        <span>$200</span>
                                        $195
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pane" id="tab6" *ngIf="currentTab === 'tab6'">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a routerLink="/FullStackDevelopment" class="d-block"><img src="assets/img/courses/courses2.jpg" alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Design</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/user2.jpg" class="rounded-circle mr-2" alt="image">
                                    <span>Sarah Taylor</span>
                                </div>
                                <h3><a routerLink="/FullStackDevelopment" class="d-inline-block">Certified Graphic Design with Free Project Course</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star-half'></i>
                                    </div>
                                    <div class="rating-total">
                                        4.5 (2 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 15 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 10 lessons
                                    </li>
                                    <li class="courses-price">
                                        $250
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a routerLink="/FullStackDevelopment" class="d-block"><img src="assets/img/courses/courses3.jpg" alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Development</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/user3.jpg" class="rounded-circle mr-2" alt="image">
                                    <span>James Anderson</span>
                                </div>
                                <h3><a routerLink="/FullStackDevelopment" class="d-inline-block">Photography Crash Course for Photographer</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bx-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        4.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 5 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 5 lessons
                                    </li>
                                    <li class="courses-price">
                                        $150
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a routerLink="/FullStackDevelopment" class="d-block"><img src="assets/img/courses/courses5.jpg" alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Management</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/user5.jpg" class="rounded-circle mr-2" alt="image">
                                    <span>Lina D'Souza</span>
                                </div>
                                <h3><a routerLink="/FullStackDevelopment" class="d-inline-block">Information About UI/UX Design Degree</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price">
                                        $178
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pane" id="tab7" *ngIf="currentTab === 'tab7'">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a routerLink="/FullStackDevelopment" class="d-block"><img src="assets/img/courses/courses2.jpg" alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Design</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/user2.jpg" class="rounded-circle mr-2" alt="image">
                                    <span>Sarah Taylor</span>
                                </div>
                                <h3><a routerLink="/FullStackDevelopment" class="d-inline-block">Certified Graphic Design with Free Project Course</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star-half'></i>
                                    </div>
                                    <div class="rating-total">
                                        4.5 (2 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 15 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 10 lessons
                                    </li>
                                    <li class="courses-price">
                                        $250
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a routerLink="/FullStackDevelopment" class="d-block"><img src="assets/img/courses/courses4.jpg" alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Language</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/user4.jpg" class="rounded-circle mr-2" alt="image">
                                    <span>Liam King</span>
                                </div>
                                <h3><a routerLink="/FullStackDevelopment" class="d-inline-block">Web Design for Developing Technology with Joy</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price">
                                        <span>$200</span>
                                        $195
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a routerLink="/FullStackDevelopment" class="d-block"><img src="assets/img/courses/courses5.jpg" alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Management</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/user5.jpg" class="rounded-circle mr-2" alt="image">
                                    <span>Lina D'Souza</span>
                                </div>
                                <h3><a routerLink="/FullStackDevelopment" class="d-inline-block">Information About UI/UX Design Degree</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price">
                                        $178
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a routerLink="/FullStackDevelopment" class="d-block"><img src="assets/img/courses/courses6.jpg" alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Photography</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/user6.jpg" class="rounded-circle mr-2" alt="image">
                                    <span>David Warner</span>
                                </div>
                                <h3><a routerLink="/FullStackDevelopment" class="d-inline-block">Photography Photo modify and Beautiful</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price">
                                        $500
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>