import { Component } from '@angular/core';

@Component({
  selector: 'app-robots',
  templateUrl: './robots.component.html',
  styleUrls: ['./robots.component.scss']
})
export class RobotsComponent {
  sitemapContent: string;
  constructor(){
    this.generateSitemap();
  }
  generateSitemap() {
    this.sitemapContent = `# robots.txt generated by simplifiedseotools.com
    User-agent: *
    Disallow: 
    Disallow: /cgi-bin/
    Sitemap: https://zeptoware.in/sitemap.xml`;
  }
}
