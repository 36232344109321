import { Component } from '@angular/core';

@Component({
  selector: 'app-hearder-style-one-recorrect',
  templateUrl: './hearder-style-one-recorrect.component.html',
  styleUrls: ['./hearder-style-one-recorrect.component.scss']
})
export class HearderStyleOneRecorrectComponent {

}
